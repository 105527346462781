@use '../../../styles' as *;

$size: 14px;

.node-total-badge {
  display: grid;
  place-items: center;
  grid-auto-columns: min-content;
  grid-template: 'icon total';
  gap: 5px;
  background: $kite-gray-20;
  max-width: min-content;
  padding: 1px 5px;
  border-radius: 8px;
  font-size: $size;

  .kite-icon {
    width: $size !important;
    height: $size !important;
  }
}
