@use '../../styles' as *;

.rate-center-validation-table {
  &__phone-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    h3 {
      margin-bottom: 0;
    }

    &__btn-group {
      display: flex;
      gap: 0.5rem;
    }
  }
  h4 {
    display: block;
    text-align: center;
    margin-top: 8px;
  }
}
