@use '../../styles' as *;

.bundle-info-card {
  background: $kite-white;
  display: grid;
  position: relative;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);

  &__header {
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &-upper {
      font-size: 20px;
      display: flex;
      justify-content: space-between;

      &-btn {
        .kite-btn {
          &__content {
            font-size: 16px;
          }
        }

        &--selected {
          .kite-btn {
            &__content {
              background: transparent !important;
              color: $kite-gray-30 !important;
              border: 1px solid $kite-gray-30 !important;
            }
          }
        }
      }
    }

    &-selected {
      color: $pqe-green-1;
    }

    &-selected {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &-lower {
      padding-bottom: 10px;
      display: flex;
      gap: 1rem;
    }

    &-icons {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .ki {
        fill: $kite-gray-30;
      }
    }
  }

  &__content {
    padding: 15px 0 0 0;
    position: relative;
    list-style: none;

    &-container {
      overflow: hidden;
      @include height-transition;
    }

    li {
      display: grid;
      grid-template-columns: 1fr min-content;
    }

    &::before {
      content: '';
      width: 100%;
      border-bottom: 1px solid $kite-gray-20;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
