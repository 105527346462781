@use '../../../styles' as *;

.left-nav-admin {
  padding-top: 32px;
  width: $admin-left-nav-width;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: white;
  height: inherit;

  &__categories {
    padding: 0;
    list-style: none;

    a {
      margin: 14px 0;
      width: 100px;
      justify-content: left;
      white-space: normal;
      color: $kite-gray-30;
      text-align: left;
    }
  }

  &__category-button {
    color: $kite-gray-30;

    a {
      color: inherit;
      margin: 5px;
      font-size: 1rem;
    }

    .nav-link {
      &__link-text {
        color: $kite-gray-30;
      }
    }
  }

  &__category-button--active {
    color: black;
    border-left: 8px solid $kite-blue-20;

    a {
      color: inherit;
      font-weight: 600;
    }

    .nav-link {
      &__link-text {
        color: black;
      }
    }
  }

  &__category-button,
  &__category-button--active {
    .nav-link.active,
    .nav-link,
    a {
      background: transparent !important;

      &:hover,
      &:focus {
        background: transparent !important;
      }

      width: 100%;
    }
  }

  a:hover {
    color: inherit;
  }

  a:focus:not(:focus-visible) {
    outline: none;
  }
}
