@use '../../styles' as *;

.estimate-dashboard {
  @include estimateTable;
  @include page-max-height;

  padding: 24px;
  min-width: 750px;
  display: grid;
  grid-auto-rows: max-content;
  place-items: stretch center;
  gap: 1.75rem;
  text-align: left;
  overflow: auto;
  background: white;

  > h1,
  > h2 {
    margin: 0;
  }

  > h1 {
    font-size: 36px;
  }

  header {
    display: grid;
    grid-template-columns: 1fr min-content min-content;
    align-items: center;
    gap: 1rem;
    width: 100%;
  }

  .page-inputs {
    width: 100%;
  }

  &__uc-table-column {
    display: flex;
    gap: 0.75rem;
    align-items: center;

    a {
      max-width: max-content;
    }
  }

  &__mne-table-column {
    display: flex;
    gap: 0.75rem;
    align-items: center;

    a {
      max-width: max-content;
    }
  }

  .kite-pagination {
    display: grid;
    place-items: center;
  }

  .kite-loader {
    background: $kite-white;
  }

  &__name-link {
    display: flex;
    align-items: center;
    gap: 0.7rem;
  }

  &__table {
    width: 100%;
  }

  .rkp-table {
    a,
    &__cell {
      @include hideClickFocus;
    }

    &__cell {
      display: inline-block;
      overflow: visible;
    }
    .optydOverlap {
      word-break: break-all;
    }
  }
}
