@use '../../styles' as *;

.estimate-location-card {
  text-align: left;

  &__header {
    display: flex;
    align-items: center;

    .autobuild-pill {
      margin-left: 16px;
    }
  }

  &__content-container {
    @include height-transition;
    overflow: hidden;
    text-align: left;
  }

  &__phone-number-section {
    margin: 1.5rem 0;
  }
}
