@use '../../styles' as *;

.sf-field-val-redirect {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .kite-alert__page {
    max-width: 43rem;
  }
}
