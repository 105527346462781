@use '../../styles' as *;

.scenario-summary {
  display: grid;
  grid-template-rows: auto(1fr);
  justify-items: flex-start;
  width: 100%;
  height: fit-content;
  margin-bottom: 0;

  &__radio-group > label {
    font-weight: 700;
  }

  &__radio-group {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    justify-items: flex-start;
    width: 100%;
    max-height: 140px;
    margin-bottom: 15px;
  }

  &__description {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: flex-start;
    width: 100%;
    height: 100%;
    padding-left: 30px;

    &-icon {
      margin-left: 5px;
    }

    p {
      margin: 0;
      padding: 0;
    }

    &-products {
      display: flex;
      flex-wrap: wrap;
      height: 21px;
      padding-top: 10px;
      align-items: center;
    }
  }
}
