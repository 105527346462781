@use '../../styles' as *;

.locations-drawer {
  .drawer {
    &__container {
      padding: 0;
    }
  }

  &__header {
    width: 100%;
    padding: 16px;
    background-color: $kite-gray-20;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  &__title {
    margin-bottom: 0;
    word-break: break-all;

    &-input {
      margin-bottom: 0 !important;
    }

    &-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.5rem;
      place-items: center start;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 16px;
    height: auto;
    overflow-y: scroll;
  }
}
