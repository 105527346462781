@use '../../../styles' as *;

.pdf-modal {
  @include kiteModalOverride;

  &__error {
    max-width: none !important;
  }

  &__message {
    margin-bottom: 1rem !important;
  }

  &__button-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}
