@use '../../styles' as *;

.bundle-data-bar {
  display: grid;
  grid-template-columns: repeat(2, min-content) max-content 1fr;
  place-items: center start;
  gap: 1rem;
  border-bottom: 1px solid $kite-gray-20;
  padding: 9px 24px;
  position: relative;

  & > *:nth-child(4) {
    justify-self: end;
  }

  &__alert {
    background: white !important;
    max-width: none !important;
    position: absolute !important;
    left: 0;
    right: 0;
    top: 100%;
    box-shadow: 0 2px 4px -2px $kite-gray-20;
  }

  button:disabled {
    .kite-btn {
      &__content {
        cursor: not-allowed;
      }
    }
  }

  &__name-input {
    max-width: none !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    p {
      margin-left: 1rem;
      margin-bottom: 0;
    }

    .kite-input {
      &__label {
        margin: 0;
      }

      &__input {
        width: 312px;
      }

      &__label-container {
        margin-right: 8px;
      }
    }
  }
}
