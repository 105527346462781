@use '../../styles' as *;

.estimate-locations-table {
  @include kiteAlert;

  max-height: calc(100vh - 180px);
  min-height: 400px;
  overflow: hidden;

  &__table-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    &-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
    }
  }

  &__table {
    overflow: auto;

    &-container {
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
    }

    &-checkbox {
      margin-left: 16px;
    }
  }
}
