@use '../../../styles' as *;

.main-layout {
  height: 100%;
  background-color: $kite-gray-10;
  min-width: min-content;
  overflow: auto;

  &--builder {
    background: $kite-gray-20;
  }
}

@media screen and (min-width: '900px') {
  .kite-alert__inner {
    max-width: none !important;
  }
}
