@use '../../../styles' as *;

.pricing-table-head {
  @include pricingTableHead();

  &__pricing-row {
    @include pricingTableRow();

    td {
      &:nth-child(1) {
        background: $kite-gray-20 !important;
        color: $kite-black;
      }

      &:nth-child(n + 1) {
        background: $kite-gray-10;
      }
    }
  }
}
