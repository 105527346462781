@use '../../styles' as *;

.cpw-cost-approval-badge {
  display: flex;
  align-items: center;
  padding: 3px 8px;
  border: 1px solid;
  border-radius: 4px;
  width: fit-content;
  color: $kite-red-20;

  &--approved {
    color: $kite-green-20;
  }
}
