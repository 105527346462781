@use '../../styles' as *;

.webex-space-card {
  @include sales-team-container;

  &__chat-card {
    width: 100%;
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    a {
      @include hideClickFocus;
    }

    &-info {
      display: flex;

      &-label {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      &-avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }

      &-desc {
        display: flex;
        flex-direction: column;
        text-align: left;

        p {
          padding: 0;
          margin: 0 0 0 16px;
        }

        &-title {
          color: $kite-gray-25;
        }
      }
    }

    &-link {
      padding: 0;
      margin: 0;
    }

    &-icon {
      color: $kite-blue-20;
      border: 1px solid $kite-blue-20;
      border-radius: 50%;
      padding: 10px;
    }
  }

  &__input-card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &-button {
      max-width: 20px;
      margin-left: 16px;
    }
  }
}
