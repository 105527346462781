@use '../../../styles' as *;

.rcq-header {
  display: grid;
  gap: 0.2rem;
  padding: 0 0 16px;
  border-bottom: 1px solid $kite-gray-20;

  h1 {
    margin: 0;
    white-space: nowrap;
    width: min-content;
    font-size: 1.75rem;
  }

  &__label {
    @include ucq-label;
  }

  &__see-all {
    .kite-btn-standalone-link {
      justify-content: flex-end;
    }
  }

  &__button--back {
    margin: 8px 0;
    max-width: max-content;
    min-width: max-content;
  }

  &__upper {
    display: grid;

    &-close {
      justify-self: end;
    }

    &-name {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  &__lower {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    .kite-select {
      margin: 0 !important;
      padding: 0 !important;

      &__select {
        border-width: 1px !important;
      }
    }

    &-section {
      display: grid;
      gap: 0.5rem;

      &__label-container {
        display: flex;
        justify-content: space-between;
      }

      .kite-card {
        height: 42px !important;
        padding: 10px;
      }

      &--address {
        .kite-card {
          display: flex;
          align-items: center;
          overflow: hidden;
          white-space: nowrap;

          span {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
