@use '../../../styles' as *;

.search-bar {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__input-wrapper {
    padding: 0;
    position: relative;
    width: 100%;
  }

  &__input {
    margin: 0;
    border-radius: 4px;
    border: $kite-gray-20 1px solid;
    height: 42px;
    width: 100%;
    text-overflow: ellipsis;
    transition: border-color 0.1s ease-in-out;

    &:hover,
    &:focus {
      border-color: $kite-blue-20;
      padding-left: 37px;
      ~ .kite-icon > svg {
        fill: $kite-blue-20 !important;
      }
    }

    &:focus,
    &:active,
    &:enabled {
      outline: none;
      padding: 0 120px 0 38px;
    }

    &:placeholder-shown {
      padding: 0 38px;
    }
  }

  &__icon {
    cursor: default;
    position: absolute !important;
    top: 50%;
    left: 10px;
    transform: translateY(-47%);
    opacity: 0.5;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    color: $kite-gray-30;
  }

  &__clear {
    width: auto !important;
    position: absolute;
    top: 50%;
    padding: 3px 5px !important;
    right: 10px !important;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    color: $kite-blue-20;
    gap: 0.6em;
    border: none;
    background: transparent;
    cursor: pointer;
    border-radius: 3px;
    transition: background 0.2s ease-in !important;

    &:hover {
      background: $kite-gray-20 !important;
      span {
        text-decoration: none;
      }
    }

    p {
      margin: 0;
      font-size: 0.8em;
    }
  }

  &__button {
    min-width: 158px !important;
  }
}
