@use '../../styles' as *;

.scenario-modal {
  @include kiteModalOverride;

  hr {
    border: 0;
    height: 1px;
    background: $kite-gray-20;
  }

  &__text {
    max-width: none;
  }

  &__radio {
    label {
      margin-bottom: 15px;
    }
  }

  &__locations {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 20px 0;

    h3,
    p {
      text-align: left;
      width: 100%;
    }

    label {
      margin-bottom: 15px;
    }
  }

  .kite-modal-footer {
    display: flex;
    justify-content: center;
    background-color: transparent;

    .kite-btn span {
      color: $kite-red-20;
    }
  }
}
