@use '../../styles' as *;

.business-rules {
  width: 100%;
  height: 100%;
  padding: 16px 40px;
  overflow-y: scroll;
  background-color: $kite-gray-20;

  &__header {
    text-align: center !important;
  }

  :first-child {
    text-align: initial;
  }
}
