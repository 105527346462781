@use '../../styles' as *;

.copy-modal {
  @include kiteModalOverride;

  .kite-modal-body {
    section {
      margin-top: 1rem;
    }
  }

  &__toggle {
    &--disabled {
      .kite-btn {
        &__content {
          cursor: not-allowed !important;
          background-color: $kite-gray-10 !important;
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    border-bottom: 1px solid $kite-gray-20;
    padding-bottom: 1rem;

    h2 {
      margin: 0;
      font-size: 24px;
      width: min-content;
      white-space: nowrap;
    }
  }

  &__locations {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 20px 0;

    h3,
    p {
      text-align: left;
      width: 100%;
    }

    label {
      margin-bottom: 15px;
    }
  }

  hr {
    border: 0;
    height: 1px;
    background: $kite-gray-20;
  }

  &__select {
    text-align: start;
    font-size: large;
  }

  // to hide the clear all button (due to react warnings)
  .rkp-multi-search__dismiss-all-container {
    display: none;
  }
}
