@use '../../styles' as *;

.validation-yes-no {
  background: $kite-gray-10;
  border: 1px solid $kite-gray-20;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 40% 18% 40%;
  place-items: center start;
  padding: 16px 24px;
}
