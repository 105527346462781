@use '../../../styles' as *;

.estimate-builder-layout {
  display: grid;

  &__loader {
    @include page-max-height;

    display: grid;
    place-items: center;

    .kite-loader {
      background: transparent;
    }
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 0.5rem;
    padding: 0 24px;

    &-back {
      .kite-icon {
        width: 24px !important;
        height: 24px !important;
      }

      svg {
        fill: $kite-black;
      }
    }
  }
}
