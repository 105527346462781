@use '../../../styles' as *;

.pricing-table {
  display: grid;
  grid-template-rows: min-content 1fr;
  background-color: $kite-gray-10 !important;
  font-weight: 400;
  position: relative;
  overflow: hidden !important;
  margin-top: 1rem;
  padding: 0 !important;

  &__title {
    color: $kite-black;
  }

  &__table {
    margin: 24px 0;
    width: 100%;

    td {
      display: grid;
      place-items: center end;
      overflow: auto;
    }

    tr {
      height: fit-content;
      display: grid;
      grid-template-columns: minmax(150px, 1fr) repeat(5, minmax(100px, 1fr));
    }

    &-group {
      overflow: auto;
      margin: 0 16px;
      @include height-transition;
    }
  }

  .chevron {
    transition: transform 0.1s ease-in-out;
    transform-origin: center;
    width: 24px !important;
    height: 24px !important;
    justify-self: end;

    &--expanded {
      transform: rotate(90deg);
    }
  }

  &__banner {
    padding: 16px !important;
    border-radius: inherit;
    width: 100% !important;
    position: sticky;
    left: 0;

    .kite-btn {
      &__content {
        display: grid;
        font-weight: 700;
        grid-template-columns: repeat(2, 1fr);
        place-items: center start;
        color: $kite-black;

        &:hover,
        &:focus {
          color: $kite-black;
          text-decoration: none;
        }
      }
    }

    &--text {
      display: flex;
      font-weight: 700;
    }
  }
}
