@use '../../styles' as *;

.bundle-detail-drawer {
  display: flex;
  flex-direction: column;
  justify-content: left;

  text-align: left;

  .drawer__container {
    padding: 30px 33px 30px 38px !important;
  }

  &__heading {
    font-size: 28px;
    font-weight: 700;
  }

  &__bundle-name {
    margin: 16px 0;

    font-size: 16px;
    font-weight: 700;
  }

  /* Button actions */
  &__actions {
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;

    button + button {
      margin-left: 30px;
    }
  }

  &__table {
    width: 100%;

    td:nth-child(1) {
      text-align: left;
    }

    td:nth-child(n + 2) {
      text-align: right;
    }

    thead,
    tfoot {
      font-weight: bold;
      font-size: 16px;
    }

    tbody {
      color: $kite-gray-30;
      font-size: 14px;
    }
  }
}
