@use '../../../styles' as *;

.filter-button {
  .kite-btn {
    &__content {
      display: flex;
      flex-direction: column;
      color: $kite-black;
      height: auto;
      min-height: 113px;

      &:focus,
      &:hover {
        background: $kite-white;
      }

      &:focus {
        color: $kite-black;
      }

      &:hover {
        color: $kite-blue-20;
      }
    }

    &-outline-primary {
      border-color: $kite-gray-20;

      &:hover {
        border-color: $kite-blue-20;
      }
    }
  }
  .body-content {
    display: grid;
    place-items: center;
  }

  &__total {
    font-size: 36px;
    font-weight: 700;
  }

  &__value {
    font-size: 20px;
  }

  &--active {
    .kite-btn {
      &__content {
        display: flex;
        flex-direction: column;
        color: $kite-blue-20;
        height: auto;
        min-height: 113px;

        &:focus {
          color: $kite-blue-20;
        }
      }

      &-outline-primary {
        border-color: $kite-blue-20;
      }
    }
  }
}
