@use '../../../styles' as *;

.pricing-table-row {
  @include pricingTableRow();

  &--addon {
    @include pricingTableRow(12px);

    td {
      padding: 7px 9px 4px 0;
    }
  }

  &--focus {
    td {
      color: black;

      &:nth-child(1) {
        background-color: $kite-gray-30;
        color: white;
      }
    }
  }
}
