@use '../../styles' as *;

.uc-profiles-page {
  @include kiteModalOverride;

  .kite-modal-body {
    display: grid;
    gap: 1rem;

    h1 {
      justify-self: center;
    }
  }
}
