@use '../../styles' as *;

.sales-team-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;

  &__header {
    font-weight: 700;
    color: $kite-gray-25;
    position: sticky;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    p {
      margin: 0;
    }
  }

  &__cards {
    width: 100%;
    display: grid;
    grid-auto-columns: minmax(max-content, 33%);
    grid-auto-flow: column;
    gap: 1rem;
    overflow: auto;
  }
}
