@use '../../styles' as *;

.unauthorized {
  &__main {
    padding: 24px;

    border-top: solid 1px $kite-gray-20;
  }

  h1 {
    margin: 20px auto;

    font-size: 28px;
    font-weight: bold;
  }

  a {
    display: inline-block;

    margin: 10px auto;

    text-decoration: none;
  }
}
