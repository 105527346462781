@use '../../styles' as *;

.submission-yes-no {
  display: grid;
  grid-template-columns: 40% 18% 40%;
  place-items: center start;
  padding: 0 24px;

  &:nth-child(odd) {
    background-color: $kite-gray-15;
  }

  &__question {
    padding: 8px 0;
    list-style: none;
  }
}
