@use '../../../styles' as *;

.rc-review-estimate-products-tab {
  @include uc-review-content;
  @include kiteAlert;

  h1 {
    font-size: 28px;
    text-align: left;
  }
}
