@use '../../styles' as *;

.scenario-tab {
  max-width: none !important;
  min-width: min-content;
  cursor: pointer;

  &__edit-btn {
    grid-area: b;
    justify-self: end;

    svg {
      fill: $kite-gray-30;
    }
  }

  span {
    text-transform: capitalize;
  }

  &__content {
    width: 100% !important;
    height: 100%;
    padding: 0.5rem 1rem 0.25rem !important;

    display: grid !important;
    grid-template: 'a b' 'c d';
    grid-template-columns: 3fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 3px;

    background: $kite-gray-15;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    text-align: left !important;
    color: $kite-black;
    font-weight: 500;
    font-size: 18px;

    & > * {
      &:first-child {
        overflow: hidden;
        width: 140px;
        text-overflow: ellipsis;
        white-space: nowrap;
        grid-area: a;
      }

      &:nth-child(2):not(.scenario-tab__edit-btn) {
        grid-area: c;
      }

      &:last-child {
        grid-area: d;
        justify-self: end;
      }
    }

    &:focus,
    &:hover {
      color: $kite-black;
      text-decoration: none;
    }
  }

  &--active {
    .scenario-tab__content {
      background: $kite-white;
    }
  }

  &--add-tab {
    align-items: center;

    .scenario-tab__content {
      font-size: 1rem !important;
      background: transparent;
      text-align: center !important;
      color: $kite-blue-20;
      display: flex !important;
      height: auto;
      padding: 0 !important;

      &:focus,
      &:hover {
        color: $kite-blue-30;
        text-decoration: none;
      }
    }
  }
}
