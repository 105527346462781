@use '../../styles' as *;

.data-page {
  @include configuration-child;
  @include kiteAlert;

  &__card {
    margin: 1.5rem 0;
  }

  &__select--fia-static-ip {
    margin-top: 16px;
  }

  &__select--fia-speed {
    @include kiteTooltipOverride;
  }
}
