@use '../../styles' as *;

.estimate-form-page {
  @include page-max-height('breadcrumb');

  padding: 0 24px;
  background-color: $kite-gray-10;
  text-align: left;
  display: grid;
  grid-template-rows: min-content 1fr min-content;

  &__contents {
    display: grid;
    grid-template-columns: minmax(252px, 300px) 3fr;
    grid-template-rows: 100%;
    grid-gap: 24px;

    &>* {
      box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
    }
  }

  &__submit {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    &-button {
      margin: 20px 0;
    }
  }

  &__redirect {
    @include page-max-height;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.sptdashboard {
  margin-left: 60px;
}