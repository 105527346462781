@use '../../styles' as *;

.mne-hospitality-badges {
  display: grid;
  gap: 0.5rem;
  width: auto;
  margin-bottom: 15px;
  grid-template-columns: repeat(5, max-content);
  align-items: end;
}
