@use '../variables' as *;

@mixin ucq-label {
  color: $kite-gray-25;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

@mixin uc-review-content {
  background-color: $kite-white;
  border: 1px solid #d8dde6;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px;
  overflow-y: visible;
  overflow-x: hidden;
}
