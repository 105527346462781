@use '../../../styles' as *;

.nav-link {
  width: 100%;

  background-color: $kite-white;
  transition: background-color 0.1s;

  display: flex;
  align-items: center;

  a {
    padding: 0 18px;
    height: 45px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;

    &:active,
    &:focus {
      background-color: $kite-gray-20;
      transition: background-color 0.3s;
      outline-offset: -2px;
    }
  }

  &__link-text {
    text-align: left;
    line-height: normal;
    color: $kite-black;
  }

  &:hover {
    background-color: $kite-gray-20;
    transition: background-color 0.3s;
  }
}

.nav-link.active {
  background-color: $kite-gray-20;
  transition: background-color 0.3s;

  .nav-link__link-text {
    font-weight: bold;
  }
}

.nav-link.disabled {
  a {
    cursor: not-allowed;
  }

  &:hover {
    background-color: $kite-white;
    transition: background-color 0.3s;
  }

  .ki {
    fill: #c8cdd6 !important;
  }

  .kite-font-icon {
    color: #c8cdd6 !important;
  }

  .nav-link__link-text {
    color: #c8cdd6;
  }
}
