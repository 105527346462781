@use '../../../styles' as *;

.rcq-form-nav {
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100%;
  padding: 1.5rem 0 0 !important;
  width: 280px;
  overflow: hidden;

  &__progress {
    padding: 0 1rem 1.5rem;
    position: sticky;
    height: $ucq-content-upper-height;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: calc(100% - 2rem);
      height: 1px;
      background: $kite-gray-20;
    }
  }

  ul {
    list-style-type: none;
    padding: 1rem 0;
    margin: 0;
    position: relative;
    min-width: 220px;
    overflow: auto;

    li {
      padding: 0 1rem;
      isolation: isolate;
      white-space: nowrap;

      a {
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center;
        gap: 0.4rem;
        color: black;
        font-weight: 700;
        text-decoration: none;
        padding: 0.25rem 0;

        &:focus,
        &:hover {
          text-decoration: none;
        }

        &:focus:not(:focus-visible) {
          outline: none;
        }

        &[class='active'] {
          color: white;
          position: relative;

          svg {
            fill: white;
          }

          &::after {
            content: '';
            height: 100%;
            width: calc(100% + 2rem);
            left: -1rem;
            right: 1rem;
            position: absolute;
            z-index: -1;
            background: $kite-dark-blue-10;
          }

          div[class*='complete-badge']:not([id='complete']) {
            border-color: white;
          }
        }
      }
    }
  }

  &__complete-badge {
    min-height: 14px;
    max-height: 14px;
    min-width: 14px;
    max-width: 14px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    border: 1px solid black;
    overflow: hidden;

    &[id='complete'] {
      background: green;
      border-color: green;
    }
  }
}
