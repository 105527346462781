@use '../../styles' as *;

.button-link {
  @mixin button-colors($bg, $border, $text) {
    background: $bg;
    border: 1px solid $border;
    color: $text;
  }

  $duration: 195ms;
  $timing: cubic-bezier(0.4, 0, 0.2, 1);

  @mixin button-hover {
    transition-property: background, border, color;
    transition-duration: $duration, $duration, $duration;
    transition-timing-function: $timing, $timing, $timing;

    &:hover,
    &:focus {
      @include button-colors($kite-blue-30, $kite-blue-30, white);

      text-decoration: none;
    }
  }

  @mixin primary {
    @include button-colors($kite-blue-20, $kite-blue-20, white);
    @include button-hover;
  }

  @mixin outline {
    @include button-colors(transparent, $kite-blue-20, $kite-blue-20);
    @include button-hover;
  }

  @mixin button-link($type: 'primary') {
    @if $type == 'primary' {
      @include primary;
    }

    @if $type == 'outline' {
      @include outline;
    }

    @include hideClickFocus;

    height: 42px;
    padding: 8px 20px;
    font-weight: 500;
    line-height: 1.5;
    white-space: nowrap;
    text-align: center;
    display: grid;
    place-items: center;
    border-radius: 0.25rem;
  }

  &--primary {
    @include button-link('primary');
  }

  &--outline {
    @include button-link('outline');
  }

  &--standalone {
    color: $kite-blue-20;
  }
}
