@use '../variables/colors' as *;
@use '../mixins/global-mixins' as *;

@mixin kiteAlert {
  .kite-alert {
    max-width: none;
    width: 100%;
    margin-bottom: 24px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid $kite-gray-20;
    border-radius: 4px;
    background: white;
    text-align: left;

    &__inner {
      max-width: none;
    }
  }
}

@mixin estimateTable {
  .rkp-table {
    &__headings-wrapper {
      border-left: none;
      border-right: none;
      border-top: none;
    }

    &__row {
      border: none;

      &:last-child {
        border: none;
      }
    }

    &__headings-item > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@mixin kiteModalOverride {
  .kite-modal {
    &-dialog {
      border-radius: 4px;
    }

    &-body {
      display: grid;
      place-items: start;
      padding: 0 2rem 1rem;

      section {
        width: 100%;

        .kite-input,
        .kite-select {
          max-width: none;
        }

        .kite-custom-checkbox {
          text-align: left;
          margin: 1rem 0;
          width: 100%;
        }
      }

      @include kiteAlert;
    }

    &__action-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }

    &__close-btn,
    &__close-btn * {
      @include hideClickFocus;
    }

    &__close-btn {
      cursor: pointer;
    }
  }
}

@mixin kiteTooltipOverride {
  & .kite-tooltip .kite-tooltip__bubble {
    position: relative;
    display: block;
    width: 400px;
    left: -85px;
    top: auto;
    transform: none;
    height: 90px;
    text-align: left;
  }

  & .kite-tooltip__bubble-bg.bottom.left .kite-tooltip__bubble:after,
  .kite-tooltip__bubble-bg.bottom.left .kite-tooltip__bubble:before {
    top: calc(100% - 3px);
    left: 100px;
  }

  & .kite-tooltip__bubble-bg {
    width: 0;
  }
}

@mixin switchDisabled {
  .kite-switch {
    &__inner,
    &__track {
      cursor: not-allowed;
    }
  }
}

@mixin stepperStyling {
  margin-bottom: 16px;

  .rkp-stepper {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &__circle {
      z-index: 1;
    }

    &__step {
      min-width: 20px !important;
    }

    &__step--left:first-child,
    &__label,
    &__circle {
      margin-left: 0 !important;
    }

    margin-bottom: 0;
  }
}

@mixin delete-btn {
  .kite-btn {
    &__content {
      color: $kite-red-20;
      border-color: $kite-red-20;

      &:focus,
      &:hover {
        color: $kite-white;
        background: $kite-red-20;
      }
    }
  }
}
