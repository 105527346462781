@use '.././../../styles' as *;

.admin-product-table {
  width: 100%;
  padding: 24px 0;
  max-width: max-content;
  min-width: 400px;

  &__header-wrapper {
    width: 100%;
    min-height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__product-name {
    text-align: left;
  }

  &__button {
    &-container {
      display: flex;
    }

    &--save {
      margin-left: 8px;
      position: relative !important;
    }
  }

  &__tabs button {
    font-size: 16px;
  }
}
