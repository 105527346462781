@use '../../../styles' as *;

.network-edge-container {
  @include kiteAlert;
  text-align: left;

  &__stepper-wrapper {
    @include stepperStyling;
    padding: 2px !important;
  }

  &__card {
    margin-bottom: 24px;
  }

  &__recommend-wrapper {
    height: 128px;
    padding: 16px;
    margin-bottom: 16px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: 4px;
    background-color: rgba(0, 191, 31, 0.1);
  }

  &__recommend-text {
    font-size: 20px;
    font-weight: bold;
    color: #3b3b3b;
  }

  &__select-recommend-text {
    position: absolute;
    top: 168px;
    left: 210px;

    color: $kite-gray-30;
  }

  &__select-details {
    padding-left: 24px;
  }

  &__button-container {
    @include button-container;
  }

  &__prev-button--hidden {
    @include button--hidden;
  }

  &__next-button--hidden {
    @include button--hidden;
    position: absolute;
  }
}

@media only screen and (max-width: 1440px) {
  .network-edge-container .rkp-stepper__step {
    width: 80px;
  }
}

@media only screen and (min-width: 1440px) {
  .network-edge-container .rkp-stepper__step {
    width: 112px;
  }
}
