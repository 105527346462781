@use '../../styles' as *;

.rcq-field {
  @include kiteAlert;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  textarea {
    resize: none;
  }

  select {
    &[placeholder='Make Selection'] {
      color: $kite-gray-30;
    }
  }

  input {
    &[type='date'][value=''] {
      color: $kite-gray-30;

      &:focus {
        color: black;
      }
    }
  }

  &__header {
    font-size: 20px;
    margin: 0;
  }

  &__label {
    display: flex;
    flex-direction: column;

    &-question {
      display: grid;
      grid-template-columns: 1fr min-content;
      place-items: center start;
    }
  }

  &__incomplete {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    white-space: pre;
    justify-self: start;
    margin-right: 1rem;

    & > *:last-child {
      align-self: center;
    }

    &-marker {
      width: 0.7rem;
      height: 0.7rem;
      margin-right: 0.5rem;
      background: $kite-yellow-10;
      border-radius: 50%;
      box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
      align-self: start;
    }
  }

  &__tooltip {
    @include tooltip-override;
  }

  &__subtext {
    color: $kite-gray-30;
  }

  &__precheck {
    margin: 0;

    &-container {
      display: grid;
      gap: 0.5rem;
    }

    .kite-radio {
      &__button-container {
        flex-direction: row;
        gap: 1rem;
      }

      &__button {
        margin: 0;
      }
    }
  }

  &__copy {
    &-container {
      display: grid;
      grid-template-columns: 1fr minmax(min-content, 0.2fr);
      gap: 1rem;
    }
  }

  &__text-quantity {
    &-container {
      display: grid;
      grid-template-columns: 1fr 0.3fr;
      grid-template-rows: repeat(2, 1fr);
      column-gap: 1rem;
      row-gap: 0;
    }
  }

  &__repeat-btn {
    &-container {
      display: flex;
      gap: 1rem;
    }
  }

  &__upload {
    @include file-upload;

    display: grid;
    gap: 1rem;

    button:disabled {
      .kite-btn {
        &__content {
          cursor: not-allowed;
        }
      }
    }
  }

  &__time-range {
    display: grid;
    gap: 1rem;
  }
}
