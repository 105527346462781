@use '../../styles' as *;

.edit-sales-team-modal {
  @include kiteModalOverride;

  .kite-modal-body {
    section {
      display: grid;
      gap: 1rem;
      margin-bottom: 1rem;
    }

    .kite-select {
      margin: 0;
    }
  }

  p {
    margin-bottom: 0;
  }

  &__search {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;

    .kite-form-group {
      margin: 0;
    }
  }

  .rkp-table {
    max-height: 400px;
    overflow: auto;
  }

  &__select {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 0.25fr;
    align-items: center;
    gap: 1rem;

    span {
      text-align: left;
    }
  }
}
