.location-form {
  margin: 8px 0;

  &__custom-address {
    margin: 8px 0;

    display: flex;
    align-content: center;

    p {
      margin: auto 8px;
    }
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
  }

  &__cancel-button {
    margin-right: 12px;
  }
}
