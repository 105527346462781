@use '../../../styles' as *;

.rc-review-validation {
  @include uc-review-content;

  overflow: hidden;

  &__products {
    display: grid;
    gap: 1rem;
  }

  &__content {
    overflow-y: auto;
    orphans: 3;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  &__form {
    text-align: left;

    &__design-flow {
      text-align: left;
      display: grid;
      grid-template-rows: 1fr repeat(2, max-content);
      gap: 0.5rem;
    }

    &__validation {
      &__headers {
        display: flex;
        padding-left: 24px;

        h3 {
          font-weight: 400;
          font-size: 16px;
        }
      }

      &__main-list {
        padding-inline-start: 0;
      }

      &__yes-no {
        display: flex;
        align-items: center;
        width: 100%;
        height: 102px;
        background: $kite-gray-10;
        border: 1px solid $kite-gray-20;
        border-radius: 4px;
        padding: 16px 24px;

        p {
          margin: 0 16px 0 0;
        }
      }

      &__content {
        display: flex;
        align-items: center;
        padding-inline-start: 24px;
        max-width: 100%;

        &--question {
          width: 40%;
          padding: 8px 0;
          margin-right: 16px;
          list-style: none;
        }

        .yes-no-switch {
          width: 18%;
        }
      }

      &__content:nth-child(odd) {
        background-color: $kite-gray-15;
      }
    }
  }
}
