@use '../../styles' as *;

.page-inputs {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__filters {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }
}
