@use '../../styles' as *;

.sme-approval-badge {
  &--approval-needed {
    @include uc-questions-badge($kite-red-20);
  }

  &--no-approval-needed {
    @include uc-questions-badge($kite-green-20);
  }
}
