@use '../../../styles' as *;

.pqe-left-nav {
  display: grid;
  grid-template-rows: min-content 1fr;
  gap: 16px;
  min-width: 180px;

  &__link-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    background: $kite-gray-10;
    padding: 0 0 24px;
    border: 1px solid $kite-gray-20;
    border-radius: 4px;
    overflow: auto;
  }

  /** Override default Switch styles so that it fits in the narrow LeftNav */
  .switch-wrapper {
    margin-bottom: 0;
    justify-self: end;
    width: min-content;
    height: 100%;
    @include hideClickFocus;

    .switch-label {
      width: auto;
      min-width: 0;
      margin: 0 13px 0 0;
      color: $kite-gray-30;
    }
  }

  &__link-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__section-title {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: normal;
    text-align: left;
    padding: 0 18px;
  }

  &__button-pdf {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
