@use '../../styles' as *;

.add-tn-modal {
  @include kiteModalOverride;

  &__form {
    display: grid;
    gap: 1rem;

    &-input-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-input-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }
}
