@use '../../styles' as *;

.configurator-default {
  height: 100%;
  display: grid;
  place-items: center;
  font-size: 28px;
  font-weight: 500;
  color: $kite-gray-30;
  text-align: center;
}
