@use '../../styles' as *;

.import-modal {
  @include kiteModalOverride;

  .kite-modal-body {
    section {
      margin-top: 1rem;
    }
  }

  &__toggle {
    &--disabled {
      .kite-btn {
        &__content {
          cursor: not-allowed !important;
          background-color: $kite-gray-10 !important;
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    border-bottom: 1px solid $kite-gray-20;
    padding-bottom: 1rem;

    h2 {
      padding: 0 0.5rem;
      margin: 0;
      font-size: 24px;
      width: min-content;
      white-space: nowrap;
    }
  }

  &__progress {
    text-align: left;
    width: 50%;
  }
}
