@use './styles' as *;

.App {
  width: 100%;
  text-align: center;
  background: $kite-white;
}

.login-page {
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    margin-top: 48px;
  }

  &__card-container {
    display: flex;

    margin-top: 16px;
  }
}

.display-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
}
