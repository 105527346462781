@use '../../styles' as *;

.promo-page {
  display: flex;
  flex-direction: column;
  text-align: left;

  &__loader {
    height: 100%;
  }

  section {
    &:nth-child(n + 2) {
      padding-top: 16px;
    }

    h2 {
      border-bottom: 1px solid $kite-gray-20;
    }
  }

  &__list-header {
    font-style: italic;
  }

  &__list {
    padding-left: 24px;
  }

  &__switch {
    padding: 0;
    margin-bottom: 1rem;

    .kite-switch {
      &__inner {
        flex-direction: row-reverse;
      }

      &__label {
        margin-right: 8px;
        font-size: 1.17em;
        font-weight: 500;
      }
    }

    &--disabled {
      @include switchDisabled;
    }
  }
}
