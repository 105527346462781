@use '../../../styles' as *;

.ene-layout {
  background: $kite-gray-10;
  display: grid;
  padding: 0 24px;
  text-align: start;

  &__form-loader {
    position: absolute !important;
    justify-self: center;
    align-self: center;
  }

  &__upper {
    display: grid;
    gap: 0.7rem;

    &-sub {
      display: grid;
      grid-template-columns: min-content 1fr 0.75fr;
      align-items: center;
      gap: 1rem;
      margin-bottom: 0.5rem;

      h2 {
        width: 220px;
        margin: 0;
      }

      .kite-tabs {
        overflow: clip;
        padding: 0 100px;

        &__tab {
          &-content {
            font-size: 1rem;
          }

          &--active {
            color: $kite-blue-20;
          }
        }
      }
    }
  }

  &__content {
    @include page-max-height('ucq');

    display: grid;
    grid-template: 1fr min-content / min-content 1fr; // rows / columns
    grid-template-areas: 'nav form' 'nav form';
    gap: 1rem;
    padding-bottom: 24px;

    & > * {
      &:nth-child(1) {
        grid-area: nav;
      }

      &:nth-child(2) {
        grid-area: form;
      }

      &:nth-child(3) {
        grid-area: help;
      }
    }

    .kite-card {
      box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
    }
  }
}
