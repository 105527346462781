@use '../../styles' as *;

.sbb-page {
  @include configuration-child;
  @include kiteAlert;

  &__header-wrapper {
    margin-bottom: 24px;
  }

  &__stepper-wrapper {
    @include stepperStyling;
  }

  &__header-text {
    text-align: left;
  }

  &__button-container {
    @include button-container;
  }

  &__prev-button--hidden {
    @include button--hidden;
  }

  &__next-button--hidden {
    @include button--hidden;
    position: absolute;
  }

  &__note-text {
    color: $kite-gray-30;
    text-align: left;
  }

  &__done-text {
    width: 274px;

    text-align: left;
  }

  &__done-button--hidden {
    display: none;
  }
}

@media only screen and (max-width: 1440px) {
  .sbb-page .rkp-stepper__step {
    width: 100px;
  }
}

@media only screen and (min-width: 1440px) {
  .sbb-page .rkp-stepper__step {
    width: 120px;
  }
}
