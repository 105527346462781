@use '../../../styles' as *;

.discretion {
  text-align: left;

  &__description {
    color: $kite-gray-30;
    margin-top: -1rem;
    margin-bottom: 0px;
  }

  &__slider {
    -webkit-appearance: none;
  }

  &__slider::-webkit-slider-thumb {
    height: 28px;
    width: 28px;

    border-radius: 50%;
    background: $kite-blue-20;
    cursor: pointer;

    -webkit-appearance: none;
    appearance: none;
  }

  &__slider::-moz-range-thumb {
    height: 28px;
    width: 28px;

    border-radius: 50%;
    background: $kite-blue-20;
    border: none;
    cursor: grab;
  }

  &__price-input-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;

    height: 42px;
    margin-bottom: 1.5rem;

    &--error {
      height: 76px;
    }

    .kite-input {
      width: 180px;
    }
    #myRange {
      height: 10px;
      width: 100%;
      margin-right: 30px;

      border-radius: 5px;
      border: solid 1px $kite-gray-30;
      background-color: $kite-white;
    }
  }

  &__input--danger {
    input {
      border-color: $kite-red-20;
    }
  }

  .kite-form-control-feedback {
    text-align: right;
    margin-top: -50px;
  }
}
