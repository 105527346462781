@use '../../../styles' as *;

.pdf-download-button {
  &__button {
    .kite-btn-primary {
      background-color: $pqe-green-2 !important;
      font-weight: bold;
    }
  }

  &__button-small,
  &__button-compact {
    .kite-btn-primary {
      height: 53px;
      margin: 1px;
      border-radius: 0;
    }
  }

  &__button-compact {
    .kite-btn-primary {
      width: 35px;
    }

    .kite-btn__content {
      padding: 0 0 0 8px;
    }
  }
}
