@use '../../styles' as *;

.estimate-builder-controls {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  padding: 16px 24px 0;

  .kite-btn {
    &__content {
      font-weight: 500;
    }
  }

  label {
    @include ucq-label;

    font-size: 0.75rem;
    color: $kite-gray-30;
    position: absolute;
    white-space: nowrap;
  }

  &__scenarios {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    width: max-content;
  }

  &__locations {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    place-items: center end;
    justify-self: end;
    min-height: 68px;

    &-controls {
      display: grid;
      gap: 1rem;
      grid-auto-flow: column;
      grid-auto-columns: min-content;

      .location-dropdown {
        min-width: 250px;
        margin-bottom: 0;
      }
    }
  }

  &__pdf-btn {
    align-items: center;

    .kite-btn {
      &__content {
        background: transparent;
        text-align: center !important;
        color: $kite-blue-20;
        display: flex !important;
        height: auto;
        padding: 0 !important;

        &:focus,
        &:hover {
          color: $kite-blue-30;
          text-decoration: none;
        }
      }
    }
  }

  &__modal--delete {
    @include kiteModalOverride;

    p {
      width: 100%;
      text-align: left;
    }
  }
}
