@use '../../../styles' as *;

.uc-review-network-draft-table {
  border: 1px solid #63738a;
  table-layout: auto;
  border-radius: 4px;

  tr {
    border-top: 1px solid #63738a;
    border-bottom: 1px solid #63738a;
    &:nth-child(odd) {
      background-color: #ebeff3;
    }
  }

  &__validation-row {
    background-color: $kite-white !important;
  }

  th {
    background-color: $kite-white;
  }

  td,
  th {
    padding: 5px 8px;
    border-right: 1px solid #63738a;
    border-left: 1px solid #63738a;
  }

  .kite-btn__content {
    height: 24px !important;
  }
}
