@use '../../styles' as *;

.copy-history-row {
  border: 1px solid $kite-gray-20;
  border-radius: 4px;
  margin-bottom: 16px;

  &__content {
    padding: 8px 16px;
  }

  button {
    @include hideClickFocus;

    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
    width: 100%;
  }

  &__avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;

    &--generic {
      svg {
        fill: $kite-blue-10;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 1rem;

    &--location {
      background: $kite-gray-10;
      border: 1px solid $kite-gray-20;
      box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
      border-radius: 4px;
      max-width: 17.5rem;
      padding: 0 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: min-content;
    }
  }

  &__sub-title {
    margin-top: 3px;
    margin-left: 48px;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &--question-change {
      color: $kite-gray-25;
    }

    &--location {
      background: $kite-gray-10;
      border: 1px solid $kite-gray-20;
      box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
      border-radius: 4px;
      max-width: 12.5rem;
      padding: 0 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: min-content;
    }

    &--time {
      color: $kite-gray-30;
    }
  }
}
