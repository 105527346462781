@use '../../styles' as *;

.select-solution-modal {
  @include kiteModalOverride;

  .kite-modal-body {
    section {
      margin-top: 1rem;
    }
  }

  &__toggle {
    &--disabled {
      .kite-btn {
        &__content {
          cursor: not-allowed !important;
          background-color: $kite-gray-10 !important;
        }
      }
    }
  }
}
