@use '../../styles' as *;

.customer-bar {
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $kite-gray-20;
  padding: 8px 16px !important;
  box-shadow: 0 1px 4px rgb(0 0 0 / 10%);

  @include kiteAlert;

  &__label {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 20px;
    text-align: left;

    &-subheader {
      text-transform: uppercase;
      color: $kite-gray-30;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-header {
      font-weight: 500;
      font-size: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
}
