@use '../../styles' as *;

.report-dashboard {
  @include estimateTable;
  @include page-max-height;

  padding: 24px;
  min-width: 750px;
  display: grid;
  grid-auto-rows: 100%;
  place-items: stretch center;
  gap: 1.75rem;
  text-align: left;
  overflow: auto;
  background: white;

  > h1,
  > h2 {
    margin: 0;
  }

  > h1 {
    font-size: 36px;
  }

  header {
    display: grid;
    grid-template-columns: repeat(0, auto) !important;
    align-items: center;
    gap: 1rem;
    width: 100%;
  }

  .page-inputs {
    width: 100%;
  }

  &__uc-table-column {
    display: flex;
    gap: 0.75rem;
    align-items: center;

    a {
      max-width: 100%;
    }
  }

  .kite-pagination {
    display: grid;
    place-items: center;
  }

  .kite-loader {
    background: $kite-white;
  }
  .report-field__time-range {
    display: flex;
    gap: 10px;
  }
  .report-field__time-range div {
    display: flex;
    align-items: center;
  }
  .report-field__time-range div > div {
    margin-bottom: 8px;
  }
  .report-field__subtext {
    margin-right: 4px;
  }

  .date-align {
    display: flex;
    justify-content: space-around;
  }

  &__name-link {
    display: flex;
    align-items: center;
    gap: 0.7rem;
  }

  &__table {
    width: 100%;
  }

  .rkp-table {
    overflow-x: scroll;
    a,
    &__cell {
      @include hideClickFocus;
    }

    &__cell {
      display: inline-block;
      overflow: visible;
      overflow-wrap: break-word; // Added property
    }
    .optydOverlap {
      word-break: break-all;
    }
  }
  .rkp-table__headings {
    grid-template-columns: repeat(11, auto) !important;
  }
}
