.location-address-input {
  &__label {
    font-weight: 400;
    margin-bottom: 4px;
    display: block;
    text-align: left;
  }

  &__error {
    text-align: left;
  }

  &__selection {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 2rem;
    place-items: center start;
  }
}
