@use '../../styles' as *;

.rate-center-search-modal {
  @include kiteModalOverride;

  &__input-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  &__message {
    margin-bottom: 1rem;
  }

  .body-content {
    margin-bottom: 1rem;
  }
}
