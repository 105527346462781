@use '../variables' as *;
@use 'sass:color';

@mixin node-body {
  padding: 32px 10px 20px;
  border: 1px solid $kite-gray-20;
  border-radius: 5px;
  background: white;
  display: grid;
  min-width: 200px;
  max-width: 200px;
  overflow: hidden;
}

@mixin node-handle {
  .react-flow {
    &__handle {
      width: 20px;
      height: 20px;
      bottom: -20px;
      background: $kite-blue-20;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      z-index: 3;
    }
  }
}

@mixin node-id($id-color) {
  background: color.adjust($id-color, $alpha: -0.9%);
  color: $id-color;
  border: 1px solid $id-color;
  padding: 0 8px;
  border-radius: 1em;
  min-width: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 0.625em;
  line-height: 1.5;
}

@mixin node-label {
  font-size: 1.2em;
  display: grid;
  grid-template-columns: repeat(3, min-content);
  align-items: center;
  gap: 0.3em;
}

@mixin node-edit-btn {
  .kite-btn {
    min-width: min-content !important;
    max-width: min-content !important;

    &__content {
      color: $kite-gray-30;

      svg {
        width: 0.825em;
        height: 0.825em;
      }
    }
  }
}

@mixin node-name {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr min-content;
  text-transform: uppercase;
  color: $kite-gray-30;
  font-size: 0.825em;
  font-weight: bold;
  white-space: nowrap;

  > span {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @include node-edit-btn;
}

@mixin node-badges {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  gap: 0.4em;
}

@mixin node-location {
  font-size: 0.825em;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin general-node-connection {
  position: absolute;
  left: 0;
  right: 0;
  color: white;
  font-weight: bold;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
}

@mixin node-connector {
  @include general-node-connection;

  background: $kite-gray-30;
  top: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

@mixin node-interconnect {
  @include general-node-connection;

  background: $kite-dark-blue-10;
  bottom: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-align: center;
}
