@use '../../styles' as *;

.output-totals {
  padding: 0 !important;

  table {
    width: 100%;

    tr {
      display: grid;
      grid-template-columns: minmax(150px, 1fr) repeat(5, minmax(100px, 1fr));
      padding: 0 16px;
      min-height: 40px;
    }

    td,
    th {
      overflow: auto;
      position: relative;
    }

    td {
      place-items: center end;
    }

    th {
      place-items: center start;
    }
  }

  &--bundle {
    .output-totals-row {
      &--top {
        border-bottom: none;
      }
    }
  }
}
