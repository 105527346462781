@use '../../styles' as *;

.copy-estimate {
  @include kiteModalOverride;

  .kite-modal-body {
    section {
      margin-top: 1rem;
    }
  }

  &-name {
    display: flex;
    font-size: 18px;
    margin-top: 15px;
    text-align: left;

    &-title {
      font-weight: 600;
    }
  }

  &-questions-type {
    margin-top: -10px;
    display: flex;
    margin-bottom: 10px;
  }

  &-select {
    margin-top: 15px !important;
  }

  // to hide the clear all button (due to react warnings)
  .rkp-multi-search__dismiss-all-container {
    display: none;
  }
}
