@use '../../../styles' as *;

.mne-review-location-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  float: left;
  width: 100%;
  border-bottom: 1px solid #d8dde6;
  margin-bottom: 16px;

  &__top {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    justify-content: space-between;
    align-items: center;

    &__oppty-name {
      color: $kite-gray-25;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 4px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .kite-btn {
      justify-self: end;
    }
  }

  &__lower {
    display: grid;
    grid-template-areas: 'a b' 'a c';
    grid-template-columns: 1fr 0.5fr;
    row-gap: 1rem;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    text-align: left;
    margin-bottom: 1rem;

    &-right {
      grid-column: 2 !important;
      grid-row: 1 !important;
      align-self: start;
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
    }
    &-left {
      grid-area: a;
      display: flex;
      align-items: flex-start;
    }

    &__title {
      font-weight: 700;
      font-size: 28px;
      grid-area: a;
      margin: 0;
      align-self: start;
    }

    &__isw {
      font-size: 18px;
      margin-top: 10px;
      &-value {
        font-weight: normal;
      }
    }

    & > *:last-child:not(:first-child) {
      grid-area: c;
    }

    .mneq-reminders {
      justify-content: start;
    }
  }
  &__device-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-direction: row;
    gap: 1rem;
    margin-left: 7rem;
  }
  &__device-select {
    width: 200px;
  }
  &__device-offers {
    min-width: 300px;
    width: auto;
    grid-area: c;
  }
  .custom-offer-textbox textarea {
    padding: 2px !important;
    box-sizing: border-box;
  }
  &__device-offers-title {
    align-items: center;
  }
  .container {
    column-gap: 20px;
    display: flex;
  }
  .device-dropdown {
    flex: 1;
    min-width: 140px;
    padding: 5px;
  }
}
