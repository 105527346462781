@use '../../styles' as *;

$results-border: 1px solid $kite-gray-30;

.clone-estimate-suggestion-input {
  width: 100%;
  position: relative;
  text-align: left;

  .kite-form-group {
    margin: 0;
  }

  &__label {
    padding-bottom: 0.5rem;
  }

  &__input {
    display: grid;
    grid-template-columns: 1fr min-content;

    .kite-form-control {
      border-right: none;

      &:focus {
        border-right: 2px solid $kite-blue-30;
      }
    }
  }

  &__arrow {
    @include hideClickFocus();

    height: 68%;
    padding: 8px;
    display: grid;
    place-items: center;
    border: $results-border;
    border-left: none;
    background: white;
    cursor: pointer;
    margin-top: 20px;

    &:focus-visible {
      outline: none;
      border: 2px solid $kite-blue-30;
    }
  }

  &__results {
    border: $results-border;
    text-align: left;
    background: white;
    border-top: none;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0px 4px 5px 3px rgb(0 0 0 / 10%);

    p {
      margin: 0;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      position: relative;
      overflow: auto;
      max-height: 400px;
    }

    li {
      @include hideClickFocus();

      display: grid;
      padding: 0.5rem;

      &:nth-of-type(even) {
        background: $kite-gray-15;
      }

      &:hover {
        cursor: pointer;
        background: $kite-gray-20;
      }

      &:focus-visible {
        outline-offset: -2px;
      }
    }

    &-name {
      display: flex;
      align-items: center;

      .kite-icon {
        margin-left: 0.5rem;
        color: $kite-blue-30;
      }
    }

    &-total {
      text-align: center;
      border-top: $results-border;
      z-index: 3;
      padding: 4px 0;
    }

    &-message {
      padding: 4px 0;
      text-align: center;
    }
  }
}
