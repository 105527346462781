@use '../../styles' as *;

.bundles {
  background: $kite-gray-10;
  text-align: left;
  display: grid;
  gap: 1rem;

  h2,
  h3 {
    font-weight: 500;
  }

  h3 {
    margin: 0;
  }

  &__group {
    display: grid;
    gap: 1rem;
  }
}
