@use '../../styles' as *;

.uc-links {
  display: flex;
  margin-bottom: 32px;

  &__container {
    height: 82px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $kite-white;
    border-bottom: 5px solid $kite-white;
    color: $kite-gray-30;

    svg {
      fill: $kite-gray-30;
    }

    &--complete {
      border-bottom: 5px solid $kite-green-20;
      color: $kite-green-20;

      .kite-icon,
      svg {
        color: $kite-green-20;
        fill: $kite-green-20;
      }
    }

    span {
      font-size: 12px;
    }
  }

  a,
  a:hover {
    text-decoration: none;
  }
}
