@use '../../../styles' as *;

.rc-review-pictures-tab {
  @include uc-review-content;
  text-align: left;
  overflow: hidden;

  &__image {
    display: grid;
    grid-template-rows: 1rem auto auto;
    gap: 0.5rem;
    width: 90%;
    margin-bottom: 24px;

    h4 {
      margin: 0;
    }

    .kite-input {
      margin-top: auto;
    }
  }

  &__content {
    overflow-y: scroll;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
