@use '../../styles' as *;

.rc-product-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 8px 0;

  .kite-btn {
    min-width: min-content !important;
  }

  img {
    width: 129px;
    height: 97px;
  }

  &__upper {
    display: grid;
    grid-template-columns: 1fr repeat(2, min-content);
    gap: 1rem;
    place-items: center start;

    .ki {
      fill: $kite-gray-25;
    }
  }

  &__lower {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: start center;
    gap: 1rem;
    text-align: left;

    ul {
      padding-left: 20px;
      margin: 0;
      line-height: 1.3;
      font-size: 12px;
    }

    &-pricing {
      display: flex;
      flex-direction: column;
      justify-self: end;
      align-self: end;
      text-align: right;
      font-size: 14px;
    }

    &-cost {
      font-size: 20px;
      font-weight: 500;
    }
  }
}
