@use '../../../styles' as *;

.rc-review-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;

  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__name {
      &-label {
        font-size: 16px;
        font-weight: 700;
        color: $kite-gray-25;
        margin-bottom: 2px;
      }

      display: flex;
      align-items: center;

      h1 {
        font-weight: 500;
        font-size: 28px;
        margin-right: 16px;
      }

      &__autobuild-pill {
        margin-bottom: 4px;
      }
    }
  }
}
