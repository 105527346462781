@use '../../styles' as *;

.rc-field-validation-header {
  width: 100%;
  display: flex;
  padding: 8px;
  color: $kite-white;
  background-color: $kite-gray-30;
  border-radius: 10px 10px 0 0;
  break-inside: avoid;

  .kite-icon {
    margin: 6px 10px 0 0;
  }
}
