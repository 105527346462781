@use '../../styles' as *;

.estimate-detail-page,
.estimate-detail-page-error {
  @include kiteAlert;
  @include page-max-height;
  @include kiteModalOverride;

  padding: 0 24px;
  background-color: $kite-gray-10;
  display: flex;
  flex-direction: column;

  &__wrapper {
    @include estimateTable;

    &--details {
      padding: 0 0 24px;
      display: grid;
      grid-template-columns: 1fr 0.5fr;
      gap: 16px;
    }

    &--navigation {
      padding: 24px 0;
      display: grid;
      grid-template-columns: repeat(2, max-content);
      justify-content: space-between;

      a {
        text-decoration: none;
      }
    }

    &--sortable-table {
      display: flex;
      justify-content: space-between;
    }

    &-buttons {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      justify-self: end;
    }

    &--alert {
      display: flex;
      justify-content: space-between;

      .kite-alert {
        max-width: 30rem;
        margin-bottom: 0;

        &__page {
          padding: 0 12px;
        }
      }
    }
  }

  &__detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    &-section {
      width: 100%;
      text-align: left;
      display: flex;
      justify-content: space-between;
    }

    &-title {
      text-transform: uppercase;
      font-weight: 700;
      color: $kite-gray-25;

      &~h2 {
        font-size: 1.75rem;
      }
    }

    &-table {
      text-align: left;

      td:nth-child(n + 2) {
        padding-left: 16px;
        font-weight: bold;
      }
    }

    &-buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &-link {
      width: 100%;
    }
  }

  &__sortable-table {
    min-width: 800px;
    text-align: left;
  }

  &__content {
    &>* {
      margin-bottom: 1rem;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & :nth-child(2) {
      margin-left: 0.5rem;
    }
  }

  &__archive {
    &-btn {
      @include delete-btn;

      align-self: flex-end;
    }
  }
}

.sptdashboard {
  margin-left: 60px;
}