@use '../../../styles' as *;

.rc-review-side-nav {
  background-color: $kite-white;
  border: 1px solid #d8dde6;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  min-width: 219px;
  margin-right: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;

  h2 {
    font-weight: 500;
    font-size: 20px;
    padding: 16px 16px 0 16px;
  }

  &__location-cell {
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a {
      text-decoration: none;
      color: inherit;
      width: 100%;
      text-align: left;
      padding: 16px;
      &:hover {
        text-decoration: none;
        color: inherit;
      }
      &:focus {
        outline: none !important;
      }
    }

    &:hover {
      background-color: $kite-gray-20;
      border-radius: 4px;
      cursor: pointer;
    }

    &--active {
      color: $kite-white;
      background-color: $kite-blue-20;
      font-weight: 700;
      border-radius: 4px;
      &:hover {
        background-color: $kite-blue-20;
      }
    }
  }
}
