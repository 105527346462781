@use '../../styles' as *;

.admin {
  @include page-max-height;

  display: grid;
  overflow: hidden;

  &__content-wrapper {
    display: grid;
    grid-template-columns: max-content 1fr;
    height: inherit;
    overflow: auto;
  }

  &__spacer {
    height: 102px;
    width: 100%;
  }
}
