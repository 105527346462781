@use '../../../styles' as *;

.edit-node-drawer {
  display: grid;
  place-items: stretch;
  gap: 1rem;

  h1 {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    align-items: center;
    gap: 0.3em;
  }

  &__demarc-switch * {
    @include hideClickFocus;
  }

  &__sub-header,
  &__demarc-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__sub-header {
    &:nth-of-type(n + 2) {
      margin-top: 1.5rem;
    }
  }

  input,
  .kite-select,
  .kite-input {
    max-width: none !important;
  }

  select {
    &[placeholder='Make Selection'] {
      color: $kite-gray-30;
    }
  }

  h2 {
    margin: 0;
  }

  &__inputs {
    > * {
      margin-bottom: 1rem;
    }
  }

  &__address {
    .kite-card {
      height: 42px !important;
      padding: 10px;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      box-shadow: none !important;
      color: $kite-gray-30;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  textarea {
    resize: none;
  }

  &__btn-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  &__delete-btn {
    @include delete-btn;
  }

  &__warning-modal {
    @include kiteModalOverride;
  }

  &__warning-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    min-height: 110px;

    &-message {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1ex;
    }

    ul {
      align-self: flex-start;
    }

    .node-label {
      &--idf,
      &--mdf,
      &--bdg {
        height: 3ch;
        width: min-content;
      }
    }
  }
}
