@use '../../../styles' as *;

.ucq-form {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1.5rem 1rem 0 !important;
  min-width: min-content;

  &__header {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    position: sticky;

    &-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1rem;
      justify-content: space-between;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $kite-gray-20;
      height: $ucq-content-upper-height;
    }

    h2 {
      padding: 0 0.5rem;
      margin: 0;
      font-size: 24px;
      border-right: 1px solid $kite-gray-20;
      width: min-content;
      white-space: nowrap;
    }

    &-complete {
      color: $kite-gray-30;
      padding-left: 0.5rem;
    }

    &-btns {
      display: flex;
      //grid-template-columns: 1fr min-content;
      column-gap: 1rem;
      align-items: center;
      width: 100%;
      justify-items: end;
    }

    &--modal {
      h2 {
        border-right: none;
      }

      border-bottom: 1px solid $kite-gray-20;
      padding-bottom: 1rem;
    }
  }

  &__fields {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 5rem;
    row-gap: 0rem !important;
    margin-top: 10px;
    overflow-y: auto;
    gap: 2rem;
    overflow: auto;
    padding: 1rem 0;
    align-items: end;

    &--flow-chart {
      display: grid;
      gap: 1rem;
      overflow: auto;
      padding: 1rem 0;
      height: 100%;
    }
  }

  > .kite-alert {
    &__page {
      position: relative;
      justify-content: flex-start;
      max-width: none !important;
      padding: 12px;
      color: #000;
      background-color: #f8f8f8;
    }
  }

  .kite-modal {
    &-body .body-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-bottom: 1rem;

      & > * {
        margin: 0;
      }
    }
  }

  &__progress {
    text-align: left;
    width: 50%;
  }
}
