@use '../../styles' as *;

.help-box {
  background: $kite-white;
  border-radius: 4px;
  border: 1px solid $kite-gray-20;
  position: relative;
  padding: 0 !important;
  margin-top: 1rem;
  transition: all 1s;
  overflow: auto;
  height: 135px;

  &__banner {
    padding: 16px !important;
    width: 100% !important;
    position: sticky;
    left: 0;

    .kite-btn {
      &__content {
        display: grid;
        font-weight: 700;
        grid-template-columns: repeat(2, min-content) 1fr;
        gap: 0.5rem;
        place-items: center start;
        color: $kite-black;

        .kite-icon {
          color: $kite-blue-20;

          &:nth-child(n + 2) {
            color: $kite-black;
          }
        }

        &:hover,
        &:focus {
          color: $kite-black;
          text-decoration: none;
        }
      }
    }

    &--expanded {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &--dark {
      background: $kite-gray-10 !important;
    }
  }

  .chevron {
    transform-origin: center;
    width: 24px !important;
    height: 24px !important;
    justify-self: end;
    transition: transform 0.1s ease-in-out;

    &--expanded {
      transform: rotate(90deg);
    }
  }

  &__content {
    padding: 0 25px;
    overflow: auto;
    @include height-transition;

    ul {
      padding: 8px 36px 18px;
      text-align: left;
      margin: 0;

      li {
        font-size: 11px;

        span {
          font-size: 16px;
        }
      }
    }
  }
}
