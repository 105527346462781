@use '../../../styles' as *;

@media print {
  .react-flow {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .react-flow__viewport {
    width: 100%;
    transform: translate(22px, 22px) !important;
    margin: 0 10px;
  }

  .design-flow-notes {
    margin-bottom: 24px;
    &__notes {
      height: auto !important;
      overflow: visible !important;
    }
    ul {
      overflow: visible !important;
    }
  }

  .rc-review-submissions {
    border: none !important;
    box-shadow: none !important;
    &__form {
      h2 {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
    }
    max-width: 100% !important;
  }
}

.rc-review-submissions {
  background-color: $kite-white;
  border: 1px solid #d8dde6;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 100%;
  width: 100%;
  flex-direction: column;
  flex: 1;
  padding: 24px;
  overflow-y: visible;
  overflow-x: hidden;

  &__header-container {
    padding-bottom: 20px;
  }

  &__content {
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 60px 0 60px;
  }

  &__form {
    text-align: left;

    h3 {
      font-weight: 500;
      font-size: 24px;
      width: 100%;
    }

    &__fields {
      column-count: 2;
      column-gap: 40px;
      padding-inline-start: 26px;
      margin-bottom: 24px;

      &__question {
        align-self: center;
        margin: 4px 32px 4px 0;
        list-style: none;
        break-inside: avoid;

        &--image-wrapper {
          padding-left: 26px;
        }

        &--image {
          img {
            max-width: 400px;
            margin: 1rem;
          }
        }
      }

      ul {
        margin-top: 8px;
        list-style: disc;
      }

      &__notes {
        width: 100%;
        margin-bottom: 24px;
        break-inside: avoid;

        &--content {
          height: 118px;
          padding: 8px;
          border: 1px solid $kite-gray-30;
        }
      }
    }
  }

  .react-flow {
    overflow: visible;

    button {
      display: none !important;
    }

    &__container {
      cursor: default !important;
    }

    &__handle {
      cursor: default !important;
      width: 0;
      height: 0;
      pointer-events: none;
      bottom: -2px !important;
    }
  }

  .design-flow-notes {
    margin-bottom: 24px;
    ul {
      overflow: visible;
    }
  }
}
