@use '../../styles' as *;

.rc-profile-form {
  margin: 0 auto;

  .kite-form-group {
    width: 100%;
    max-width: 100%;
  }

  &__input-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 1rem;

    p {
      margin-bottom: 0;
    }
  }

  &__input {
    &:nth-child(1) {
      grid-column: 1 / 5;
      grid-row: 1;
    }

    &:nth-child(2) {
      grid-column: 5 / 6;
      grid-row: 1;
    }

    &:nth-child(3) {
      grid-column: 1 / 6;
      grid-row: 2;
    }
  }
}
