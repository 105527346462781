@use '../../styles' as *;

.copy-history-modal {
  @include kiteModalOverride;

  h1 {
    margin-bottom: 16px;
  }

  h2 {
    margin-bottom: 4px;
  }

  ul {
    &::before {
      content: attr(aria-label);
      margin: -3ch;
    }
  }

  &__body {
    text-align: left;
  }

  &__answer {
    word-break: break-word;

    &--none {
      color: $kite-gray-30;
    }
  }

  .kite-modal {
    &__action-container {
      grid-template-columns: 1fr;
    }

    .kite-btn {
      width: 100%;
    }
  }
}
