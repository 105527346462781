@use '../../../styles' as *;

.node-connector-handle {
  @include node-handle;

  overflow: hidden;

  &:focus-within {
    outline: 2px solid $kite-blue-20;
  }
}
