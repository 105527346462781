@use '../../styles' as *;

.output-totals-row {
  &--bottom {
    td,
    th {
      display: grid;
    }
  }

  &--top {
    border-bottom: $pricing-table-border;
    background-color: $kite-gray-10;

    th {
      display: grid;
    }

    td {
      font-weight: 800;
    }

    td:nth-child(n + 4) {
      color: $pqe-green-1;
    }
  }

  th {
    padding-left: 8px;
    border-right: $pricing-table-border;
    font-size: 18px;
    font-weight: normal;
  }

  td:nth-child(n + 1) {
    padding-right: 8px;
    border-right: $pricing-table-border;
    text-align: end;
  }

  td:last-child {
    border-right: none;
  }

  span {
    font-size: 12px;
    font-weight: 700;
    position: sticky;
    top: 0;
    right: 0;
  }
}
