@use '../../../styles' as *;

.location-card {
  @include location-card;

  &__info {
    &-container,
    &-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &-wrapper {
      width: 300px;
      flex-direction: column;
      text-transform: capitalize;
    }

    &-container {
      width: 100%;
      align-items: center;
    }
  }

  &__name,
  &__address,
  &__seats {
    margin: 4px 0;
    display: block;

    text-align: left;
    white-space: normal;
    word-break: break-all;
  }

  &__name {
    color: $kite-blue-20;
    font-size: 16px;
    word-break: break-all;
  }

  &__seats,
  &__address {
    font-size: 14px;
    word-break: keep-all;
  }

  &__seats {
    min-width: 70px;
  }
}
