@use '../../styles' as *;

.mne-progress-button {
  isolation: isolate;
  position: relative !important;

  $duration: 195ms;
  $timing: cubic-bezier(0.4, 0, 0.2, 1);

  &__progress {
    position: absolute;
    height: 5px;
    background: $kite-blue-20;
    bottom: -1px;
    left: -1px;
    right: -1px;
    z-index: -1;
    border-bottom-left-radius: 0.25rem;
    transition-property: background;
    transition-duration: $duration;
    transition-timing-function: $timing;

    &--full {
      border-bottom-right-radius: 0.25rem;
    }
  }

  &:hover {
    .mne-progress-button {
      &__progress {
        background: $kite-blue-30;
      }
    }
  }
}
