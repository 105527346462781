/* Add new colors to the export at bottom for storybook */

/* KITE DESIGN WEB COLORS -- START */

/* Primary */
$kite-dark-blue-30: #001019;
$kite-dark-blue-20: #002133;
$kite-dark-blue-10: #004366;
$kite-blue-30: #0062b2;
$kite-blue-20: #0073d1;
$kite-blue-10: #008cff;

/* Neutral */
$kite-black: #000000;
$kite-gray-30: #63738a;
$kite-gray-25: #9ba9bd;
$kite-gray-20: #d8dde6;
$kite-gray-15: #ebeff3;
$kite-gray-10: #f8f8f8;
$kite-white: #fff;
$kite-light-blue-10: #b7cee5;

/* Status */
$kite-green-20: #008156;
$kite-green-10: #00bf1f;
$kite-yellow-20: #feb533;
$kite-yellow-10: #ffd400;
$kite-red-20: #d6312b;
$kite-red-10: #ff4d4a;
$kite-light-blue-20: #5db8fc;

/* KITE DS WEB COLORS -- END */

/* -------- */

/* TEAM DEFINED COLORS -- START */

$kite-dark-blue-30-a55: #0010198c;
$pqe-green-2: #11ae2b;
$kite-blue-20-a66: #0073d166;
$pqe-green-1: #008516;
$pqe-yellow-1: #faa41a;
$pqe-disabled-fg: #c8cdd6;

/* TEAM DEFINED COLORS -- END */

/* -------- */

/* EXPORT FOR STORYBOOK */
// prettier-ignore
:export {
  Primary: {
    kite-dark-blue-30: $kite-dark-blue-30;
    kite-dark-blue-20: $kite-dark-blue-20;
    kite-dark-blue-10: $kite-dark-blue-10;
    kite-blue-30: $kite-blue-30;
    kite-blue-20: $kite-blue-20;
    kite-blue-10: $kite-blue-10;
  }

  Neutral: {
    kite-black: $kite-black;
    kite-gray-30: $kite-gray-30;
    kite-gray-25: $kite-gray-25;
    kite-gray-20: $kite-gray-20;
    kite-gray-15: $kite-gray-15;
    kite-gray-10: $kite-gray-10;
    kite-white: $kite-white;
    kite-light-blue-10: $kite-light-blue-10;
  }

  Status: {
    kite-green-20: $kite-green-20;
    kite-green-10: $kite-green-10;
    kite-yellow-20: $kite-yellow-20;
    kite-yellow-10: $kite-yellow-10;
    kite-red-20: $kite-red-20;
    kite-red-10: $kite-red-10;
    kite-light-blue-20: $kite-light-blue-20;
  }

  TeamDefined: {
    kite-dark-blue-30-a55: $kite-dark-blue-30-a55;
    kite-blue-20-a66: $kite-blue-20-a66;
    pqe-green-1: $pqe-green-1;
    pqe-green-2: $pqe-green-2;
    pqe-yellow-1: $pqe-yellow-1;
    pqe-disabled-fg: $pqe-disabled-fg; 
  }
}
