@use '../../../styles' as *;

.rcq-reminders {
  justify-self: end;
  display: grid;
  gap: 0.5rem;
  width: 100%;

  &__label {
    @include ucq-label;
  }

  &__badges {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    gap: 0.5rem;
  }
}
