@use '../../styles' as *;

@media print {
  ::-webkit-scrollbar {
    display: none;
  }

  .rc-review-location-header {
    display: none;
  }

  .rc-review-submissions {
    &__form {
      break-inside: avoid;
    }
  }

  .rc-review-validation {
    &__form {
      break-inside: avoid;
    }
  }

  .review-network-draft-table {
    width: 100% !important;
    transform: scale(85%);
  }

  .yes-no-switch {
    width: 100% !important;
  }

  td .yes-no-switch {
    display: flex;
    width: 88px;
    justify-content: center;
  }

  .react-flow {
    &__viewport {
      transform: translate(22px, 22px) !important;
      transform: scale(80%);
      margin: 0 10px;
    }
  }

  .design-flow-notes {
    margin-bottom: 24px;

    &__notes {
      height: auto !important;
      overflow: visible !important;
    }

    ul {
      overflow: visible !important;
    }
  }

  .rc-review-cpw-cost-table {
    transform: scale(98%) !important;
  }
}

.rc-review-page {
  height: 100vh;
  padding: 24px;
  background: $kite-gray-10;
  display: flex;
  flex-direction: column;

  &__loader {
    @include page-max-height;
  }

  &__content {
    height: 90%;
    display: flex;
    justify-content: center;

    &--main {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-width: 80%;
    }

    .kite-tabs {
      margin-bottom: 24px;

      &__tab--active {
        color: $kite-blue-20;
      }
    }
  }
}
