@use '../../../styles' as *;

.pqe-nav-link {
  width: 100%;
  transition: background-color 0.1s;
  display: grid;
  grid-template-columns: 1fr min-content;
  place-items: center start;

  .kite-icon {
    fill: $kite-black;
  }

  &__expand {
    transform-origin: center center;
    transition: transform 0.1s ease-out;

    &--open {
      transform: rotate(90deg);
    }
  }

  &__children {
    width: 100%;
    .pqe-nav-link {
      padding-left: 0.7rem;
    }
  }

  a {
    padding-left: 18px;
    height: 45px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    place-items: center start;
    text-decoration: none !important;
    position: relative;
    @include hideClickFocus();
  }

  &__link-text {
    text-align: left;
    line-height: normal;
    color: $kite-black;
    max-width: 120px;
    min-width: 90px;
  }

  &__error-wrapper {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 10px;
  }

  &:hover,
  &:focus-within,
  &--active {
    background-color: $kite-blue-20;
    transition: background-color 0.3s;

    .pqe-nav-link__link-text {
      color: $kite-white;
    }

    svg {
      fill: $kite-white;
    }

    .nav-switch {
      &-wrapper {
        @include hideClickFocus();
      }

      &__track {
        outline: 1px solid $kite-white !important;
        border: 2px solid $kite-gray-30 !important;
      }

      &--on {
        .nav-switch__track {
          outline: 1px solid $kite-white !important;
          border: 2px solid $kite-blue-20 !important;
        }
      }
    }
  }

  .nav-switch {
    &-wrapper {
      @include hideClickFocus();
    }

    &__track {
      outline: 1px solid $kite-white !important;
      border: 2px solid $kite-gray-30 !important;

      &--disabled {
        background-color: $kite-gray-30 !important;
      }
    }

    &--on {
      .nav-switch__track {
        outline: 1px solid $kite-white !important;
        border: 2px solid $kite-blue-20 !important;
      }
    }
  }

  &:hover,
  &--active {
    .pqe-nav-link__link-text {
      font-weight: bold;
    }
  }

  &--disabled {
    a {
      cursor: not-allowed;
    }

    &:hover {
      background-color: $kite-white;
      transition: background-color 0.3s;
    }

    .ki {
      fill: $pqe-disabled-fg !important;
    }

    .pqe-nav-link__link-text {
      color: $pqe-disabled-fg;
    }
  }
}
