@use '../../../styles' as *;

.rc-review-picture-modal {
  @include kiteModalOverride;
  .kite-modal {
    &-dialog {
      max-width: 75rem;
    }
  }

  img {
    height: 100%;
    width: 100%;
  }
}
