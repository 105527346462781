@use '../../styles' as *;

.custom-checkboxes {
  display: flex;
  flex-direction: column;
  color: $kite-gray-30;

  &__notes {
    list-style: none;
    text-align: left;
    padding: 0;

    li {
      margin-bottom: 1rem;
    }
  }
}
