@use '../../styles' as *;

.feedback {
  @include kiteModalOverride;

  h1 {
    width: 100%;
  }

  .feedback-form {
    &__submit {
      width: 100% !important;
    }
  }
}
