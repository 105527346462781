@use '../../styles' as *;

.install-option {
  @include kiteModalOverride;
  // .kite-modal-body {
  //   section {
  //     margin-top: 1rem;
  //   }
  // }

  &__toggle {
    font-size: 18px;
    min-width: min-content !important;

    &--disabled {
      .kite-btn-standalone-link {
        cursor: not-allowed !important;
        color: $kite-gray-30 !important;
      }
    }
  }

  &-table {
    td {
      display: grid;
      overflow: auto;
    }

    tr {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 30px;
      margin-left: 90px;
      font-weight: 500;
      align-items: center;
    }
  }

  &__message {
    margin-bottom: 1rem !important;
  }

  &-close-button {
    width: 150px;
    height: 40px;
    margin-top: 10px;
    font-size: 20px;
    cursor: pointer;
  }
}
