.esso-login {
  margin-right: 64px;

  &__card {
    width: 400px;
    padding: 32px 50px 64px 50px;
    background-color: #0a2742 !important;
    min-height: 560px;
    color: #fff;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }

  &__button {
    margin-top: 32px;
  }

  &__contact-container {
    text-align: left;
    margin-top: 32px;
  }

  &__contact-text {
    margin-bottom: 0;
  }
}
