@use '../../styles' as *;

.yes-no-switch {
  width: 88px;
  display: flex;
  align-items: center;

  &__yes {
    .kite-btn__content {
      border-radius: 4px 0 0 4px !important;
    }
    &--active {
      .kite-btn__content {
        color: $kite-white !important;
        background-color: $kite-green-20 !important;
        border-color: $kite-green-20 !important;
      }
    }
  }

  &__no {
    .kite-btn__content {
      border-left: none !important;
      border-radius: 0 4px 4px 0 !important;
    }
    &--active {
      .kite-btn__content {
        color: $kite-white !important;
        background-color: $kite-red-20 !important;
        border-color: $kite-red-20 !important;
      }
    }
  }

  .kite-btn {
    &__content {
      border: 1px solid $kite-gray-30;
      border-radius: 0px;
    }
    &-lg {
      width: 44px;
      min-width: 44px;
    }

    &-primary {
      color: $kite-gray-30;
      background-color: inherit;
    }
  }
}
