@use '../../../styles' as *;

.rc-review-cpw-tab {
  @include uc-review-content;

  &__sub-header {
    text-align: left;
    border-bottom: 1px solid #d8dde6;

    &__top {
      display: flex;
      justify-content: space-between;

      h1 {
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 16px;
      }

      &__right {
        display: flex;
        align-items: flex-start;

        img {
          width: 9rem;
        }
      }

      &__totals {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 2rem;

        &__cost-diffs {
          display: flex;
          justify-content: space-between;
          background: $kite-gray-10;
          border: 1px solid $kite-gray-20;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          height: 3.5rem;
          margin-bottom: 1.125rem;

          &--title {
            font-weight: 400;
            font-size: 18px;
          }

          &__item + &__item {
            border-left: solid 1px $kite-gray-20;
          }

          &__item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0.5rem;

            &--label {
              font-weight: 700;
              font-size: 12px;
            }

            &--amount {
              font-weight: 800;
              font-size: 16px;
            }

            &--approved {
              color: $kite-green-20;
            }

            &--not-approved {
              color: $kite-red-20;
            }
          }
        }
      }
    }

    &__bottom {
      display: flex;

      &-loc {
        margin-right: 30px;
      }
    }
    p {
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 8px;
    }
  }

  &__content {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 16px;

    &__fields {
      column-count: 2;
      .rcq-field {
        break-inside: avoid;
      }
    }
  }
}
