@use '../../styles' as *;

.dropdown-switch {
  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  &__labeled-switch {
    margin-left: 16px;
    margin-bottom: 0;

    span {
      z-index: 1 !important;
    }
  }
}
