@use '../../styles' as *;

.product-pricing {
  height: inherit;

  &__wrapper {
    display: grid;
    grid-template-columns: max-content 1fr;
    height: inherit;
  }

  &__navigation-wrapper {
    background-color: $kite-gray-20;
    width: $admin-table-nav-width;
    text-align: start;
    overflow: auto;

    h2 {
      margin: 25px 0 0 20px;
    }

    ul {
      height: calc(100% - 61px);
      padding: 20px;
    }
  }

  &__select {
    width: 100%;
    display: block;
    padding: 12px;
    text-decoration: none;
    border: none;
    background-color: transparent;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    letter-spacing: normal;
    color: #000;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: black;
    }

    &:focus:not(:focus-visible) {
      outline: none;
    }
  }

  &__select--active {
    background-color: $kite-blue-20;
    border-radius: 4px;
    color: $kite-white;

    &:hover {
      color: white;
    }
  }

  &__table-wrapper {
    height: 100%;
    padding: 16px 40px;
    overflow: auto;
    text-align: left;
  }
}
