@use '../../../styles' as *;

.uc-review-estimate-products-tab {
  @include uc-review-content;
  @include kiteAlert;

  h1 {
    font-size: 28px;
    text-align: left;
  }
}

#rcPdfImages {
  display: none;
}

.rcImage {
  padding: 10px;
  width: 100%;
  height: fit-content;
}
