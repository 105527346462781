@use '../../styles' as *;

.custom-switch {
  width: 100%;

  &--disabled {
    @include switchDisabled;
  }

  .kite-switch__inner {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
