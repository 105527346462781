@use '../../../styles' as *;

.rcq-design-flow-layout {
  display: grid;
  grid-template-rows: minmax(180px, 1fr) repeat(2, max-content);
  grid-template-columns: 1fr;
  gap: 0.5rem;
  overflow-y: auto;

  .kite-btn {
    &__content {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
