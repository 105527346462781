@use '../../../styles' as *;

.bdg-node {
  border: 1px solid $kite-gray-20;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
  transform: scaleX(1.08);
  white-space: nowrap;
  overflow: auto;
  background: white;

  &__header {
    font-size: 1.3em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5em;
    padding: 1em;

    @include node-edit-btn;

    &-id {
      @include node-id($kite-yellow-20);

      &--mdf {
        @include node-id($kite-blue-20);
      }

      &--idf {
        @include node-id($kite-green-20);
      }
    }

    &-right,
    &-left {
      display: flex;
      align-items: center;
      gap: 0.5em;
    }

    &-right {
      &-ids {
        display: flex;
        gap: 3px;
        line-height: 1;
      }
    }
  }

  &__total {
    border: 1px solid $kite-gray-20;
    border-radius: 5px;
    font-weight: bold;
    padding: 0 5px;
    font-size: 0.6em;
  }
}
