@use '../../styles' as *;

.progress-bar {
  font-weight: 500;
  font-size: 14px;

  .rkp-progress-bar__percentage {
    background-color: $pqe-green-1 !important;
  }

  .rkp-progress-bar__total {
    height: 8px;
    background-color: $kite-gray-20 !important;
  }

  &__main {
    margin: 5px 0;
  }
}
