@use '../../styles' as *;

.pqe-location-card {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;

  h1 {
    font-size: 20px;
    padding: 0;
    margin: 0 0 5px 0;
  }

  .kite-input {
    max-width: none !important;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    font-size: 14px;

    &-address {
      display: grid;
      text-align: left;
    }
  }

  &__content {
    overflow: hidden;
    @include height-transition;

    ul {
      position: relative;
      padding: 15px 0 10px 0;
      list-style-type: none;

      &::before {
        content: '';
        width: 100%;
        border-bottom: 1px solid $kite-gray-20;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    li {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-items: start;
    }

    &-value {
      font-weight: 600;
    }

    &--editable {
      ul {
        padding: 0 0 10px 0;

        &::before {
          width: 0;
        }
      }
    }
  }
}
