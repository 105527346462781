@use '../../../styles' as *;

.node-label {
  &--mdf {
    @include node-id($kite-blue-20);
  }

  &--idf {
    @include node-id($kite-green-20);
  }

  &--bdg {
    @include node-id($kite-yellow-20);
  }

  &--demarc {
    @include node-id($kite-dark-blue-10);
  }
}
