@use '../../styles' as *;

.offer-dashboard {
  height: 100%;
  padding: 16px 40px;
  overflow-y: scroll;
  background-color: $kite-gray-20;
  min-width: max-content;

  &__header-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .kite-btn {
    &__content.kite-btn-standalone-link.kite-btn-link {
      justify-content: flex-start;
    }
  }

  /* Search Input */
  .rkp-filter-search {
    &__input-wrapper {
      margin-top: 30px;

      input {
        border-color: $kite-gray-20;

        &:focus {
          border-color: $kite-gray-30;
        }
      }
    }

    &__input--invalid,
    &__input--invalid:focus {
      border: 1px solid $kite-gray-20 !important;
    }
  }

  .rkp-table {
    &__headings,
    &__row-title,
    &__hoverable,
    &no-click {
      grid-template-columns:
        minmax(0, 0.40fr) /* Offer Name */
        minmax(0, 0.20fr) /* Device */
        minmax(0, 0.10fr) /* Type */
        minmax(0, 0.10fr) /* Edit */
        minmax(0, 0.10fr) /* Delete */ !important;
    }

    &__headings-title {
      width: auto;
      font-size: 1.25rem;
      font-weight: bold;
      text-align: left;
    }

    /* Table border-radii */
    &__headings-wrapper {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    /* Table headings */
    &__headings-item {
      justify-content: start;
      overflow: hidden;
      font-size: 0.875rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:nth-child(n + 8) {
        justify-self: center;
      }
    }

    &:nth-child(n + 2) {
      margin: 30px 0;
    }

    &__cell {
      display: grid !important;
      justify-content: start;
      align-items: start;
      overflow: auto;

      &:nth-child(n + 6) {
        width: min-content;
        display: grid !important;
        place-items: center;
        justify-self: center;
      }

      button {
        color: $kite-blue-20;
        cursor: pointer;
      }
    }

    &__row:last-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .rkp-table {
        &__row-title {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  /** Error message: max featured length of 3 offers reached */
  .kite-alert {
    &__page {
      &--alert,
      &--alert:active,
      &--dark,
      &--dark:active {
        width: 100%;
        max-width: 100%;
        background-color: $kite-white;
        box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
      }
    }
  }
}



.scenario-modal-offer {
  @include kiteModalOverride;

  hr {
    border: 0;
    height: 1px;
    background: $kite-gray-20;
  }

  &__text {
    max-width: none;
  }

  &__radio {
    label {
      margin-bottom: 15px;
    }
  }

  &__locations {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 20px 0;

    h3,
    p {
      text-align: left;
      width: 100%;
    }

    label {
      margin-bottom: 15px;
    }
  }

  .kite-modal-footer {
    display: flex;
    justify-content: center;
    background-color: transparent;

    .kite-btn span {
      color: $kite-red-20;
    }
  }
}

