@use '../../styles' as *;

.sales-bom-modal {
  @include kiteModalOverride;

  .kite-modal-body {
    section {
      margin-top: 1rem;
    }
  }

  &__toggle {
    &--disabled {
      .kite-btn {
        &__content {
          cursor: not-allowed !important;
          background-color: $kite-gray-10 !important;
        }
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    font-size: 24px;
    font-weight: 500;
    border-bottom: 1px solid $kite-gray-20;
    padding-bottom: 1rem;

    img {
      width: fit-content;
      height: fit-content;
      margin-bottom: 5px;
    }

    h2 {
      margin: 0;
      font-size: 24px;
      width: min-content;
      white-space: nowrap;
    }

    p {
      font-size: 20px;
      font-weight: 400;
    }
  }

  &__content {
    table {
      font-size: large;
      width: 102%;
      border: 2px solid black;
      border-collapse: collapse;
    }
    th {
      padding: 5px;
      text-align: center;
      border: 2px solid black;
      background-color: #bcd3f7;
    }
    td {
      text-align: left;
      padding: 5px;
   
    }
    td:not(:last-child) {
      border-right: 2px solid black;
    }
    td:last-child {
      text-align: center;
      width: 30%;
    }
    td:first-child {
      font-weight: bold;
      width: 24%;
    }
    
  }
}

.sales-bom-pdf {
  padding: 20px;
}
