@use '../../styles' as *;

.estimate-review-section {
  &__body {
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-items: flex-start;
    width: 100%;
    overflow: auto;
    height: inherit;

    &-details {
      display: grid;
      grid-template-rows: repeat(2, min-content) 1fr;
      grid-template-columns: 1fr;
      justify-content: flex-start;
      width: 100%;
      overflow-y: auto;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
    }
  }
}
