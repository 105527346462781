@use '../../../styles' as *;

.login-form {
  &__title-container {
    max-width: 463px;
    margin-right: 106px;
  }

  &__card {
    min-height: 560px;
    width: 400px;
    padding: 32px 50px 64px 50px;

    background-color: #0a2742 !important;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
  }

  &__language-button {
    width: 100px;
    margin-top: 50px;

    .kite-btn__content {
      display: inline-block;

      text-align: left;
    }
  }

  &__or-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    margin-bottom: 16px;

    p {
      margin: 0;
    }
  }

  &__divider {
    height: 2px;
    width: 45%;

    background-color: $kite-blue-20;
  }

  &__create-username {
    margin-bottom: 48px;
  }

  &__page-alert {
    margin-top: 32px;
  }

  &__form {
    width: 100%;
    margin-top: 32px;
  }

  .kite-btn {
    margin-bottom: 32px;
  }

  &__forgot-password {
    margin-bottom: 32px;
  }

  .kite-link {
    text-decoration: underline;
  }

  &__contact-container {
    margin-top: 32px;

    text-align: left;

    .kite-link {
      text-decoration: none;
    }
  }

  &__contact-text {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;

    margin-top: 48px;

    &__language-button {
      margin: 15px auto 0;
    }

    &__title-container {
      width: 400px;
      min-width: 300px;
      margin: 0 0 32px 0;

      text-align: center;
    }
  }

  @media only screen and (max-width: 450px) {
    margin-top: 24px;

    &__title-container {
      width: 90%;
    }

    &__card {
      width: 95%;
      padding: 16px;
    }
  }
}
