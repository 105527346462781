@use '../../styles' as *;

.estimate-builder-content {
  @include page-max-height('estimate-builder');

  display: grid;
  grid-template: min-content 1fr min-content / max-content repeat(
      2,
      minmax(450px, 1fr)
    );
  grid-template-areas:
    'a b c'
    'a b d'
    'a b d';
  column-gap: 1rem;
  background: $kite-white;
  padding: 24px;
  min-height: 500px;

  &--sandbox {
    @include page-max-height('sandbox');
  }

  & > * {
    &:first-child {
      grid-area: a;
    }

    &:nth-child(2) {
      grid-area: b;
    }
  }

  & > *:nth-child(n + 2) {
    overflow-y: auto;
  }

  & > *:nth-child(n + 2),
  .term-input,
  .pqe-left-nav > * {
    box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
  }

  &__outlet {
    padding: 1rem !important;
    background: $kite-gray-10 !important;
    height: 100%;

    h3 {
      text-align: left;
    }
  }
}
