@use '../variables' as *;

@mixin data-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: 1620px) {
    width: 800px;
  }

  @media only screen and (min-width: 1920px) {
    width: 60%;
    padding-left: 465px;
    justify-content: center;
  }
}

@mixin configuration-child {
  width: 100%;

  .kite-select,
  .kite-input {
    max-width: none !important;
  }
}

@mixin button-container {
  width: 100%;
  margin-top: 36px;

  display: flex;
  justify-content: space-between;
}

@mixin button--hidden {
  visibility: hidden;
}

@mixin location-card {
  min-height: 76px;
  width: 100%;
  margin: 8px 0;
  padding: 0 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border: solid 1px $kite-gray-20;
  border-radius: 4px;

  &__input-icons-wrapper {
    width: 112px;

    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &__info-icon,
  &__drag-icon {
    color: $kite-blue-20;
  }
}

@mixin height-transition {
  transition: height 0.1s ease-out;
}

@mixin hideClickFocus() {
  &:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
  }
}

@mixin pricingTableRow($font-size: 14px) {
  td {
    padding: 10px 9px 7px 0;
    border-right: $pricing-table-border;
    border-bottom: $pricing-table-border;
    color: $kite-black;
    font-size: $font-size;

    &:nth-child(1) {
      border-right: none;
      border-left: none;
      text-align: start;
      padding: 0 10px;
      display: grid;
      place-items: center start;
    }

    &:nth-child(2) {
      border-left: none;
    }

    &:nth-child(n + 2) {
      background-color: $kite-white;
      text-align: end;
    }

    &:last-child {
      border-right: none;
    }
  }
}

@mixin pricingTableHead($font-size: 1rem) {
  td {
    padding: 10px 9px 7px 0;
    color: $kite-black;
    font-size: $font-size;
    font-weight: 700;

    &:nth-child(1) {
      border-right: none;
      border-left: none;
      text-align: start;
      padding: 0 10px;
      text-overflow: ellipsis;
      display: grid;
      place-items: center start;
    }

    &:nth-child(2) {
      border-left: none;
    }

    &:nth-child(n + 2) {
      text-align: end;
      color: $kite-gray-30;
    }

    &:last-child {
      border-right: none;
    }
  }
}

@mixin totalsBadge($color: $kite-black, $itemTotal: 1) {
  max-width: min-content;
  padding: 3px;
  font-size: 12px;

  border: 1px solid $kite-gray-20;
  border-radius: 5px;

  background: $kite-white;
  color: $color;
  svg {
    fill: $color;
  }

  display: grid;
  place-items: center;
  grid-template-columns: repeat($itemTotal, min-content);
  gap: 2px;
}

@mixin tooltip-override {
  .kite-tooltip {
    &__button {
      border-radius: 50%;

      &:focus {
        outline: 1px solid black;
        outline-offset: 1px;

        &::after {
          display: none;
        }
      }

      svg {
        top: 0 !important;
      }
    }
  }
}

@mixin uc-questions-badge($color: $kite-black) {
  color: $color;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 4px;
  padding: 3px 8px;
  width: fit-content;
  height: 26px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@mixin file-upload {
  margin-bottom: 1rem;

  .rkp-file-drop {
    &__dropzone {
      display: none !important;
    }
  }

  img,
  embed {
    margin-top: 1rem;
    width: 40%;
    max-width: 500px;
  }

  em {
    color: $kite-gray-30;
  }

  &-btns {
    display: flex;
    align-items: center;
    gap: 1rem;

    .kite-btn {
      &:disabled {
        &__content {
          cursor: not-allowed !important;
        }
      }
    }
  }

  .kite-alert {
    margin-top: 0.5rem;
  }
}

@mixin sales-team-container {
  min-width: 308px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.25rem;
  border: 1px solid $kite-gray-20;
  overflow: auto;
  padding: 10px;
}
