.dropdown-input {
  display: flex;
  flex-direction: column;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 0.2fr;
    gap: 1rem;
  }

  &__description {
    padding-left: 24px;
  }
}
