@use '../../../styles' as *;

.design-flow-notes {
  padding: 1rem !important;

  &__notes {
    transition: height 0.3s ease-in;
    overflow: hidden;
  }

  &__label {
    height: 24px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;

    .kite-btn {
      &__content {
        font-weight: bold !important;
      }
    }
  }

  ul {
    margin: 0;
    padding: 10px 0 0;
    overflow: scroll;

    li {
      display: grid;
      margin-bottom: 0.5rem;
      grid-template-columns: repeat(3, max-content);
      align-content: center;
      gap: 5px;
    }
  }
}
