@use '../../styles' as *;

.header {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
  box-shadow: 0 2px 8px rgba(0 0 0/ 10%);
  padding: 0 24px;
  height: 42px;
  width: 100%;
  background-color: $kite-white;
  color: $kite-gray-30;
  position: relative;
  top: 0;
  z-index: 2;

  &__name {
    margin-left: 8px;
  }

  &__logo {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 1.01rem;
    font-weight: 500;
    margin: 0;
    gap: 0.75rem;
    color: inherit;
    text-decoration: none;

    &:focus:not(:focus-visible) {
      outline: none;
    }
  }

  .kite-con-menu {
    height: 100%;

    &__menu {
      min-width: 200px;

      &-item {
        &-btn {
          cursor: pointer;
        }
      }
    }

    &__button {
      color: $kite-black;
      border: none;
      align-items: center;
      gap: 4px;

      &:focus:not(:focus-visible) {
        outline: none;

        &::after {
          border: none;
        }
      }
    }
  }
}
