@use '../../styles' as *;

.ship-to-form {
  &__shipping-input {
    margin: 1rem 0;
    padding: 0 26px;
    display: grid;
    gap: 1rem;

    .kite-select,
    .kite-input,
    .kite-radio {
      max-width: none !important;
      margin: 0 !important;
    }
  }

  &__custom-address {
    display: grid;
    grid-template: repeat(2, min-content) / repeat(3, 1fr); // rows / columns
    grid-template-areas: 'a1 a1 a1' 'a2 a2 a2' 'c s z';
    gap: 1rem;

    .kite-select,
    .kite-input {
      max-width: none !important;
      margin: 0 !important;
    }

    &--address1 {
      grid-area: a1;
    }

    &--address2 {
      grid-area: a2;
    }

    &--city {
      grid-area: c;
    }
  }
}
