@use '../../styles' as *;

.back-button {
  @include hideClickFocus;

  &__content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
