@use '../../styles' as *;

.addport-pill {
  min-width: 130px;
  width: min-content;
  display: flex;
  place-content: center;
  gap: 8px;
  font-size: 12px;
  background: $pqe-green-1;
  color: white;
  padding: 2px 0;
  border-radius: 16px;
  font-weight: 500;
  max-height: 22px;

  &--non-addport {
    background: $kite-red-20;
  }

  &--non-drt {
    background: $pqe-yellow-1;
  }

  &--icon-only {
    min-width: 37px;
  }

  &--total-power {
    min-width: 140px;
  }

  &--non-mnequestion {
    background: $pqe-yellow-1;
    min-width: 150px;
  }

  &--select-solution {
    min-width: 180px;
  }

  &--non-mnesolution {
    background: $pqe-yellow-1;
    min-width: 180px;
  }
}

.pill-container {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pill-text-color {
  color: $pqe-green-1;

  &--negative {
    color: $kite-red-20;
  }

  &--warning {
    color: $pqe-yellow-1;
  }
}
