@use '../../../styles' as *;

.mdf-node {
  @include node-body;
  @include node-handle;

  padding-top: 10px;

  &__name {
    @include node-name;
  }

  &__labels {
    @include node-label;
  }

  &__location {
    @include node-location;
  }

  &__badges {
    @include node-badges;
  }

  &__interconnect {
    @include node-interconnect;
  }
}
