@use '../../../styles' as *;

.uc-review-field-ops-upload {
  &__file-upload {
    @include file-upload;
  }

  &__preview {
    position: relative;

    img {
      width: 100%;
      max-width: unset;
      border-radius: 12px;
    }

    &__expand-icon {
      display: flex;
      position: absolute;
      min-width: 42px !important;
      width: 42px !important;
      height: 42px;
      right: 24px;
      top: 42px;
      color: $kite-white;
      background: $kite-blue-20;
      border-radius: 4px;
      bottom: 0;

      .kite-btn__content {
        padding: 0;
      }
    }
  }
}
