@use '../../../styles' as *;
@use 'sass:color';

.rcq-design-flow {
  height: 100%;
  width: 100%;

  .react-flow {
    isolation: isolate;
    border: 1px solid $kite-gray-20;
    background: $kite-gray-10;
    border-radius: 0.25rem;

    &__minimap {
      &-mask {
        fill: color.adjust($kite-gray-30, $alpha: -0.83%);
      }
    }

    &__attribution {
      a {
        color: $kite-gray-10;
        pointer-events: none;
        display: none;
      }
    }

    &__nodes {
      z-index: -1;
    }

    &__edges {
      z-index: 1;
    }

    &__container {
      cursor: grab;
    }

    &__node {
      cursor: default;
      z-index: 3 !important;
    }

    &__handle {
      cursor: pointer;
    }
  }
}
