@use '../../../styles' as *;

.rc-review-cpw-cost-table {
  text-align: left;

  &__section {
    margin-bottom: 24px;

    &__header {
      margin-bottom: 24px;
      h2 {
        font-weight: 500;
        font-size: 20px;
        margin: 0;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        color: $kite-gray-30;
        margin: 0;
      }
    }

    table {
      width: 100%;
      margin-bottom: 24px;

      tr {
        height: 3.5rem;
        border-top: 1px solid #63738a;
        border-bottom: 1px solid #63738a;
        &:nth-child(odd) {
          background-color: #ebeff3;
        }
      }

      th {
        height: 3.5rem;
        padding: 5px 8px;
        background-color: $kite-white;
      }

      td,
      th {
        min-width: 8rem;
        border-right: 1px solid #63738a;
        border-left: 1px solid #63738a;
        text-align: center;
      }
    }

    .kite {
      &-form-group {
        margin: 0;
        max-width: unset;
      }
    }

    input {
      border: none;
      background-color: inherit;
      text-align: center;
    }

    &__footer {
      display: flex;
      justify-content: space-between;

      &__totals {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &--cost {
          margin-bottom: 8px;
          border-bottom: 1px solid $kite-gray-20;
        }
      }
    }
  }

  &__sum {
    display: flex;
    justify-content: space-between;

    h3 {
      font-weight: 500;
      font-size: 20px;
    }

    &--amount {
      display: block;
      font-weight: 400;
      font-size: 20px;
    }
  }

  &__btn-group {
    display: flex;
  }

  &__desc {
    text-align: left !important;
    width: 42rem;

    input {
      text-align: left !important;
    }
  }

  &__cost-input {
    &--total {
      color: $kite-gray-30;
    }

    .kite-form-group::before {
      content: '$';
      position: relative;
      display: flex;
      height: 0;
      top: 0.5rem;
      left: 0.5rem;
    }
  }
}
