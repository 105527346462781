@use '../../../styles' as *;

.new-mdf-modal {
  @include kiteModalOverride;

  .kite-modal {
    &-body {
      text-align: left;
    }

    &__action-container {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }

  &__subtext {
    color: $kite-gray-30;
  }

  &__content {
    display: grid;
    place-items: stretch;
    gap: 1rem;
    margin-top: 2rem;

    > * {
      margin-bottom: 0.5rem !important;

      &:last-child {
        margin-bottom: 1.75rem !important;
      }
    }

    input,
    .kite-select,
    .kite-input {
      max-width: none !important;
    }

    select {
      &[placeholder='Make Selection'] {
        color: $kite-gray-30;
      }
    }
  }
}
