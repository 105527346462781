@use '../../../styles' as *;

.ucq-history-drawer {
  text-align: left;

  h1 {
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 1rem;
  }
}
