@use '../../styles' as *;

.labelled-input {
  position: relative;
  width: 100%;
  min-width: 300px;

  span {
    transition: font-size 0.08s ease-out, color 0.1s ease-in;
  }

  select {
    option:first-of-type {
      display: none;
    }
  }

  &--active {
    input {
      padding-bottom: 0 !important;
    }

    select {
      padding: 10px 42px 0 8px !important;
    }
  }

  &__label {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: grid;
    place-items: center start;
    padding-left: 8px;
    color: $kite-gray-30;
    pointer-events: none;
    white-space: pre;

    &--active {
      top: 2.8px;
      place-items: start;
      font-size: 0.6rem;
      color: $kite-blue-20;
    }

    &--error {
      color: $kite-red-20;
    }
  }
}
