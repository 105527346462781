@use '../../../styles' as *;

.nav-switch-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}

.nav-switch-label {
  margin-right: 24px;

  min-width: 200px;
  text-align: left;

  &--disabled {
    color: #96afc1;
  }
}

.nav-switch {
  position: relative;

  display: flex;
  align-items: center;

  &__inner {
    display: inline-flex;
    align-items: center;
  }

  &--on {
    .nav-switch__track {
      background-color: $kite-blue-20;
      border-color: $kite-blue-20;

      .nav-switch__toggle {
        transform: translateX(22px);
      }

      .nav-switch__track-label {
        &--off {
          opacity: 0;
        }

        &--on {
          opacity: 1;
        }
      }
    }
  }

  &__track {
    position: relative;

    display: inline-block;

    width: 51px;
    margin-right: 10px;

    background-color: $kite-gray-30;
    border: 1px solid $kite-gray-30;
    border-radius: 20px;
  }

  &__toggle {
    display: block;

    width: 25px;
    height: 25px;

    border-radius: 50%;
    background-color: $kite-white;
    box-shadow: 1px 1px 3px $kite-gray-30;
    transition: all 0.3s;
  }

  &__track-label {
    display: inline-block;
    position: absolute;
    top: 8px;

    font-size: 9px;
    font-weight: 700;
    line-height: 1em;
    color: $kite-white;
    transition: all 0.3s;
    pointer-events: none;
    &--off {
      right: 2px;

      text-align: right;
    }

    &--on {
      left: 3px;

      opacity: 0;
    }
  }

  &:focus {
    outline: none;

    .nav-switch__track::after {
      content: '';
      position: absolute;
      top: -5px;
      left: -5px;

      height: calc(100% + 10px);
      width: calc(100% + 10px);

      border: 2px solid $kite-gray-30;
      border-radius: 20px;
    }
  }

  &--on:focus {
    outline: none;

    .nav-switch__track::after {
      content: '';
      position: absolute;
      top: -5px;
      left: -5px;

      height: calc(100% + 10px);
      width: calc(100% + 10px);

      border: 2px solid $kite-blue-20;
      border-radius: 20px;
    }
  }

  &__label--disabled {
    color: #96afc1;
    cursor: not-allowed;
  }

  &__track--disabled {
    cursor: not-allowed;
    background-color: #e8ebf0;
    border: 2px solid $kite-gray-20;
  }

  &--disabled {
    cursor: not-allowed;
  }
}
