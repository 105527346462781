@use '../../styles' as *;

.autobuild-pill {
  min-width: 130px;
  width: min-content;
  display: flex;
  place-content: center;
  gap: 8px;
  font-size: 12px;
  background: $pqe-green-1;
  color: white;
  padding: 2px 0;
  border-radius: 16px;
  font-weight: 500;
  max-height: 22px;

  &--non-autobuild {
    background: $pqe-yellow-1;
  }

  &--icon-only {
    min-width: 37px;
  }
}
