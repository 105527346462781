@use '../../../styles' as *;

.custom-address-form {
  @include kiteModalOverride;

  &__input-container {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .kite-modal-dialog {
    min-width: 800px;
  }
}
