@use '.././../../styles' as *;

.editable-table {
  th,
  td,
  input {
    font-weight: 400;
    font-size: 14px;
  }

  &__table {
    margin-bottom: 24px;
    width: 100%;

    tbody {
      th,
      td {
        border: 1px solid $kite-gray-20;
        background-color: $kite-gray-10;
        padding: 8px;
      }

      td {
        width: 120px;
        text-align: right;

        &[class='editable-table__input'] {
          padding: 0;
        }

        input {
          height: 34px;
        }
      }

      th {
        width: 280px;
      }
    }

    thead {
      th {
        text-align: right;

        &:first-of-type {
          text-align: left;
        }
      }
    }
  }
}
