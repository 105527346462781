@use '../../styles' as *;

.mne-ene-badges {
  display: grid;
  gap: 0.5rem;
  width: auto;
  margin-bottom: 15px;
  grid-template-columns: repeat(10, max-content);
  align-items: end;

  &-isw-price {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
