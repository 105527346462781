@use '../../styles' as *;

.salesforce-review-page {
  @include kiteAlert;
  @include kiteModalOverride;
  @include page-max-height('breadcrumb');

  padding: 0 24px 24px;
  background-color: $kite-gray-10;
  text-align: left;

  &__contents {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    min-height: 600px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
  }

  &__button-link {
    width: 288px;
    margin-right: 0.5rem;
  }

  &__product-list {
    margin-top: 16px;
  }
}
.salesforce-alert {
  max-width: 30% !important;
}
