@use '../../styles' as *;

.expand-button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100% !important;

  .ki {
    fill: $kite-gray-30;
  }
}
