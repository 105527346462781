@use '../../styles' as *;

.bundle-editor {
  @include page-max-height;
  @include kiteModalOverride;

  min-width: 1000px;
  width: 100vw;
  overflow-y: auto;

  &__loader {
    width: 100vw !important;
  }

  &__content {
    height: calc(100vh - 59px - $main-header-height);
    width: 100%;
    display: grid;
    grid-template: min-content 1fr max-content/ min-content 1fr 1fr;
    grid-template-areas:
      'a b c'
      'a b d'
      'a b d';
    grid-column-gap: 1rem;
    column-gap: 1rem;
    background: #fff;
    padding: 24px;
    min-height: 500px;

    & > * {
      &:first-child {
        grid-area: a;
        display: flex;
        flex-direction: column;
        box-shadow: none;

        .pqe-left-nav {
          &__link-wrapper {
            height: 100%;
          }
        }
      }

      &:nth-child(2) {
        grid-area: b;
      }

      &:nth-child(3) {
        grid-area: c;
      }

      &:nth-child(4) {
        grid-area: d;
      }
    }

    & > *:nth-child(n + 2) {
      overflow-y: scroll;
      box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
    }
  }

  .pqe-left-nav > * {
    box-shadow: 0 1px 4px rgba(0 0 0 / 10%);
  }

  &__outlet {
    padding: 1rem !important;
    background-color: $kite-gray-10 !important;

    h3 {
      text-align: left;
    }
  }

  &__table-wrapper {
    width: 100%;
    margin-top: 0;
    display: flex;
    flex-direction: column;

    .pricing-table {
      height: 100%;
    }
  }

  &__outputs-table {
    min-height: 50px;
    overflow: hidden;
    padding: 0 !important;

    table {
      width: 100%;
    }
  }
}
