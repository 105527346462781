@use '../../../styles' as *;

.quantity-card {
  margin: 1rem 0;

  &--static {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;

    text-align: left;

    &:nth-child(n + 2) {
      border-top: 1px solid $kite-gray-20;
      padding-top: 1rem;
    }

    &-body {
      display: grid;
      grid-template-columns: 5fr 1fr;
      gap: 1rem;
    }

    ul {
      color: $kite-gray-30;
    }
  }

  &--expandable {
    .rkp-smart-exp__content--active {
      max-height: 10500px;
    }
  }
}
