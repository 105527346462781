@use '../../styles' as *;

.add-product-prices {
  width: 100%;
  background-color: $kite-gray-20;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
