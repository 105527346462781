@use '../../../styles' as *;

.rc-review-location-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  float: left;
  border-bottom: 1px solid #d8dde6;
  margin-bottom: 16px;
  width: 90%;

  &__top {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    justify-content: space-between;
    align-items: center;

    &__oppty-name {
      color: $kite-gray-25;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 4px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .kite-btn {
      justify-self: end;
    }
  }

  &__lower {
    display: grid;
    grid-template-areas: 'a b' 'a c';
    grid-template-columns: 1fr 0.5fr;
    row-gap: 1rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    margin-bottom: 1rem;

    &__title {
      font-weight: 700;
      font-size: 28px;
      grid-area: a;
      margin: 0;
      align-self: start;
    }

    & > *:last-child:not(:first-child) {
      grid-area: c;
    }

    .rcq-reminders {
      justify-content: end;
    }
  }
}
