.discretionary-button {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &__button {
    margin-left: 16px;
    margin-bottom: 1.5rem;
  }
}
