@use '../../../styles' as *;

.rcq-help {
  display: grid;
  grid-template-rows: min-content 1fr;
  overflow: auto;
  padding: 1.5rem 1rem 0 !important;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $kite-gray-20;
    height: $ucq-content-upper-height;

    h2 {
      position: sticky;
      margin: 0;
      font-size: 24px;
    }
  }

  &__content {
    display: grid;
    grid-auto-rows: max-content;
    gap: 1rem;
    padding: 1rem 0;
    height: inherit;
    overflow: auto;

    * {
      margin: 0;
    }

    ul {
      display: grid;
      gap: 1rem;
      padding: 0;

      li {
        list-style: none;

        &::before {
          content: '-';
          margin-right: 0.25rem;
        }
      }
    }
  }
}
