@import '~@kite/react-kite/dist/react-kite.css';
@import '~@kite/react-kite/dist/kite-icons.min.css';
@import '~@kite/react-kite-plus/dist/react-kite-plus.css';

:root {
  --green: #12ae2b;
}

body {
  margin: 0;
  font-family: Rutledge, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
