@use '../../styles' as *;

.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  padding: 1rem 24px;
  background: transparent;

  &__crumb {
    @include hideClickFocus;

    &-content {
      display: flex;
      align-items: center;
      gap: 0.1rem;
    }

    &-label {
      max-width: 40ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--current {
      color: black;
    }
  }
}
