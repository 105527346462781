@use './colors' as *;

$pricing-table-border: 1px solid $kite-gray-20;
$ucq-content-upper-height: 59px;
$admin-left-nav-width: 210px;
$admin-table-nav-width: 320px;
$main-header-height: 42px;
$breadcrumb-height: 56px;
$est-bldr-upper-height: 162px; // content above est builder (customer bar + estimatebuilder controls)
$sandbox-controls-height: 84px; // content above sandbox buidler (estimatebuilder controls w/o scenario tab)
$ucq-upper-height: 214px; // content above ucq forms (ucq header, etc.)

@mixin page-max-height($type: 'header') {
  // use to control height for page wrappers
  @if $type == 'header' {
    height: calc(100vh - $main-header-height);
  }

  @if $type == 'breadcrumb' {
    height: calc(100vh - $main-header-height - $breadcrumb-height);
  }

  @if $type == 'estimate-builder' {
    height: calc(
      100vh - $main-header-height - $breadcrumb-height - $est-bldr-upper-height
    );
  }

  @if $type == 'sandbox' {
    height: calc(100vh - $main-header-height - $sandbox-controls-height);
  }

  @if $type == 'ucq' {
    height: calc(
      100vh - $main-header-height - $breadcrumb-height - $ucq-upper-height
    );
  }
  @if $type == 'rcq' {
    height: calc(
      100vh - $main-header-height - $breadcrumb-height - $ucq-upper-height
    );
  }
}
