@use '../../styles' as *;

.sales-team-card {
  @include sales-team-container;

  position: relative;
  gap: 1rem;

  img {
    object-fit: cover;
  }

  .kite-loader {
    position: absolute;
    inset: 0;

    &__loader {
      height: 100% !important;
      width: 100% !important;
    }
  }

  &__date-updated {
    color: $kite-gray-30;
    font-style: italic;
  }

  .kite-link {
    margin: 0;
    border: 1px solid $kite-blue-10;
    border-radius: 50%;

    &:focus {
      outline: 1px solid $kite-blue-20;

      &:not(:focus-visible) {
        outline: none;
      }
    }
  }

  &__member {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;

    &-avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;

      &--generic {
        svg {
          fill: $kite-blue-10;
        }

        ~ use {
          height: 48px;
        }
      }
    }

    &-desc {
      margin-left: 8px;
      white-space: nowrap;
      display: grid;
      overflow: hidden;
      text-overflow: ellipsis;
      grid-template-columns: 1fr max-content 1fr;
      gap: 5px;

      > * {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        padding: 0;
        margin: 0;
      }
    }

    &-title {
      color: $kite-gray-25;
    }
  }

  &__link {
    padding: 0;
    margin: 0;

    &-container {
      display: flex;
      gap: 1rem;
    }
  }

  &__icon {
    color: $kite-blue-10;
    padding: 10px;
  }

  &--ucq {
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    overflow: hidden;

    .sales-team-card {
      &__member {
        &-avatar {
          width: 30px !important;
          height: 30px !important;

          &--generic {
            width: 30px !important;
            height: 30px !important;

            svg {
              fill: $kite-blue-10;
            }

            ~ use {
              height: 30px;
            }
          }
        }

        &-title {
          color: $kite-gray-25;
        }
      }

      &__link-container {
        .kite-link {
          width: 30px !important;
          height: 30px !important;
        }
      }

      &__icon {
        color: $kite-blue-10;
        padding: 5px;
      }
    }
  }
}
